import { Injectable } from '@angular/core';
import { User } from 'app/models/api/user';
import { ApiService } from 'app/services/api/api.service';
import { map } from 'rxjs/operators';
import { ResponseParser } from 'app/services/api/parsers/response-parser';
import { ParsedResponse } from 'app/models/api/response';

export interface PersonalDataMeta {
    replacedAbout: string;
    aboutReplacements: string[];
}

@Injectable({
    providedIn: 'root',
})
export class ScreeningPersonalDataApiService {
    constructor(
        private apiService: ApiService,
        private responseParser: ResponseParser,
    ) {}

    getPersonalDataUsers() {
        return this.apiService
            .get('/gem/sitly-users/flagged-personal-data')
            .pipe(map(response => this.responseParser.parseObject<User[]>(response)));
    }

    getPersonalDataStats() {
        return this.apiService
            .get('/gem/sitly-users/flagged-personal-data?meta-only=1')
            .pipe(map(response => response as ParsedResponse<unknown, { count: number }>));
    }

    saveUserData(userIds: string[], changes: string[]) {
        return this.apiService.post('/gem/sitly-users/flagged-personal-data', {
            body: {
                action: 'replace-and-warn',
                ids: userIds,
                changes,
            },
        });
    }

    ignoreUsers(userIds: string[]) {
        return this.apiService.post('/gem/sitly-users/flagged-personal-data', {
            body: {
                action: 'ignore',
                ids: userIds,
            },
        });
    }
}
