import { Photo } from 'app/models/api/photo';
import { User } from 'app/models/api/user';
import { Warning, WarningType } from 'app/models/api/warning';

export class SelectableUser {
    warningGroups: WarningGroup[] = [];
    photos: SelectableUserPhoto[] = [];
    constructor(
        public user: User,
        public selected = false,
    ) {
        this.initPhotos();
        this.initWarningGroups();
    }

    private initPhotos() {
        if (this.user.links?.avatar && !this.user.links.avatar.includes('/photos')) {
            this.photos.push({
                userId: this.user.id,
                url: this.user.links?.avatar.replace('[size]', '200'),
                type: 'avatar',
                id: this.user.id,
                warning: this.findPhotoWarning(null),
                label: 'Avatar',
            });
        }
        this.user.photos?.forEach((item, index) => {
            this.photos.push({
                userId: this.user.id,
                type: 'photo',
                url: item.links?.photo,
                id: item.id,
                warning: this.findPhotoWarning(item),
                label: index === 0 && this.user.links?.avatar?.includes('/photos') ? 'Photo as avatar' : `Photo #${index + 1}`,
            });
        });
    }

    private findPhotoWarning(photo: Photo | null) {
        return this.user.warnings.find(
            warning => warning.warningType === WarningType.avatar && (warning.photo?.id === photo?.id || (!warning.photo && !photo)),
        );
    }

    private initWarningGroups() {
        this.user.warnings.forEach(item => {
            if (!item.warningType) {
                return;
            }

            // for now group only avatar warnings
            if (item.warningType !== WarningType.avatar) {
                const groupedWarning = new WarningGroup(item.warningType);
                groupedWarning.warnings.push(item);
                this.warningGroups.push(groupedWarning);
            } else {
                let group = this.warningGroups.find(item => item.type === WarningType.avatar);
                if (!group) {
                    group = new WarningGroup(WarningType.avatar);
                    this.warningGroups.push(group);
                }
                group.warnings.push(item);
            }
        });
    }
}

export interface SelectableUserPhoto {
    label: string;
    userId: string;
    id: string;
    type: 'avatar' | 'photo';
    url?: string;
    warning?: Warning;
}

export class WarningGroup {
    warnings: Array<Warning> = [];
    get warningPhrases() {
        const result: string[] = [];
        this.warnings.forEach(item => {
            if (item.warningPhrases) {
                result.push(item.warningPhrases);
            }
        });
        return result;
    }
    get warningText() {
        return this.warnings[0]?.warningText;
    }
    get receiverId() {
        return this.warnings[0]?.message?.receiverId;
    }

    constructor(public type: WarningType) {}
}
