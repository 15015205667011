import { Component } from '@angular/core';
import { UserLog } from 'app/services/api/user.api.service';
import { BaseUserProfileTabComponent } from 'app/components/user-profile/base-user-profile-tab.component';

@Component({
    selector: 'logs-tab',
    templateUrl: './logs-tab.component.html',
    styleUrls: ['./logs-tab.component.less'],
})
export class LogsTabComponent extends BaseUserProfileTabComponent {
    totalLogsCount = 0;
    logs: { log: UserLog; expanded: boolean }[] = [];

    onUserLoaded() {
        super.onUserLoaded();

        this.userApiService.getUserLogs(this.user.id).subscribe(res => {
            this.logs = res.data.map(log => ({ log, expanded: false }));
            this.totalLogsCount = res.meta.total;
        });
    }
}
