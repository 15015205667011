import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'round-checkbox',
    templateUrl: './round-checkbox.component.html',
    styleUrls: ['./round-checkbox.component.less'],
})
export class RoundCheckboxComponent {
    @Input() id?: string;
    @Input() label?: string;

    @Output() changed = new EventEmitter<boolean>();

    @ViewChild('checkbox', { static: true }) checkbox?: ElementRef<HTMLInputElement>;

    @Input() set checked(value: boolean) {
        if (this.checkbox) {
            this.checkbox.nativeElement.checked = value;
        }
    }

    get checked() {
        return this.checkbox?.nativeElement.checked ?? false;
    }

    onLabelClick() {
        if (this.checkbox) {
            this.checkbox.nativeElement.checked = !this.checkbox.nativeElement.checked;
            this.onCheckboxChange();
        }
    }

    onCheckboxChange() {
        this.changed.emit(this.checked);
    }
}
