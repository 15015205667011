import { UserWarningApiService, WarnedUserAction } from 'app/services/api/user.warning.api.service';
import { TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { ViewPhotoDialogComponent } from 'app/components/common/alert-dialog/view-photo/view-photo.component';
import { BaseUserProfileTabComponent } from 'app/components/user-profile/base-user-profile-tab.component';
import { Photo } from 'app/models/api/photo';
import { ParentSearchPreferencesInterface, Gender } from 'app/models/api/user';
import { ImgSizeUtil } from 'app/utils/img-size.utils';
import { HttpErrorResponse } from '@angular/common/http';
import { allDayParts, allWeekDays, DayPart, WeekDay } from 'app/types';

@Component({
    selector: 'profile-tab',
    templateUrl: './profile-tab.component.html',
    styleUrls: ['./profile-tab.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileTabComponent extends BaseUserProfileTabComponent {
    get userAvailability() {
        return this.user.availability;
    }
    get parentSearchPreferences() {
        return this.user.searchPreferences as ParentSearchPreferencesInterface;
    }
    get fosterChores() {
        return this.user.fosterProperties?.fosterChores;
    }
    get fosterProperties() {
        return this.user.fosterProperties;
    }
    showWarningEmailConfirmation = false;

    DayPart = DayPart;
    WeekDay = WeekDay;
    ImgSizeUtil = ImgSizeUtil;
    allWeekDays = allWeekDays;
    allDayParts = allDayParts;

    private cd = inject(ChangeDetectorRef);
    private userWarningApiService = inject(UserWarningApiService);

    onError(_error: HttpErrorResponse) {
        this.cd.markForCheck();
    }

    sendWarningEmail() {
        this.userApiService.sendWarningEmail(this.user.id).subscribe(_ => {
            this.showWarningEmailConfirmation = true;
            this.cd.markForCheck();
            setTimeout(() => {
                this.showWarningEmailConfirmation = false;
                this.cd.markForCheck();
            }, 3_000);
        });
    }

    removeAvatar() {
        this.userWarningApiService.postWarnedUsersAction(WarnedUserAction.deleteAvatar, [this.user.id]).subscribe(() => this.refreshUser());
    }

    removePhoto(photoId: string) {
        this.userApiService.deleteUserPhoto(this.user.id, photoId).subscribe(() => this.refreshUser());
    }

    toggleAvailability(day: WeekDay, dayPart: DayPart) {
        const dayAvailability = this.getDayAvailability(day);
        if (!dayAvailability) {
            return;
        }

        if (dayPart === DayPart.morning) {
            dayAvailability.morning = !dayAvailability.morning;
        } else if (dayPart === DayPart.afternoon) {
            dayAvailability.afternoon = !dayAvailability.afternoon;
        } else if (dayPart === DayPart.evening) {
            dayAvailability.evening = !dayAvailability.evening;
        }
        this.saveAvailability();
    }

    toggleDayAvailability(day: WeekDay) {
        const dayAvailability = this.getDayAvailability(day);
        if (!dayAvailability) {
            return;
        }

        const allChecked = dayAvailability.morning && dayAvailability.afternoon && dayAvailability.evening;
        dayAvailability.morning = !allChecked;
        dayAvailability.afternoon = !allChecked;
        dayAvailability.evening = !allChecked;
        this.saveAvailability();
    }

    private saveAvailability() {
        if (this.user.isParent) {
            this.saveUserUpdate({
                availabilityPreference: this.userAvailability,
            });
        } else {
            this.saveUserUpdate({
                availability: this.userAvailability,
            });
        }
    }

    onAvatarClicked() {
        this.matDialog.open(ViewPhotoDialogComponent, {
            data: { photoUrl: ImgSizeUtil.url(this.user.links?.avatar ?? '', 500) },
        });
    }

    onPhotoClicked(photo: Photo) {
        this.matDialog.open(ViewPhotoDialogComponent, {
            data: {
                photoUrl: photo.links?.photo,
            },
        });
    }

    protected initHeaderItems() {
        super.initHeaderItems();

        if (this.user.isParent) {
            const childrenCount = this.user.children.length;
            const ages = this.user.children.map(item => item.age ?? 0);
            const min = Math.min(...ages);
            const max = Math.max(...ages);
            const childAges = childrenCount <= 1 ? (min < 0 ? 'unborn' : `aged ${min}`) : `aged ${min < 0 ? 'unborn' : min} to ${max}`;
            this.headerItems.push({
                text: `${this.user.gender === Gender.male ? 'Dad' : 'Mom'}, ${childrenCount} ${
                    childrenCount <= 1 ? 'child' : 'children'
                } ${childAges}`,
            });
        } else {
            const titleCase = new TitleCasePipe();
            this.headerItems.push({
                text:
                    `${titleCase.transform(this.user.role)},` +
                    ` ${titleCase.transform(this.user.gender)}, ${titleCase.transform(this.user.age?.toString())} years old`,
            });
        }
        this.headerItems.push({
            text: `Address: ${this.user.streetName} ${this.user.houseNumber}, ${this.user.postalCode ? this.user.postalCode + ' ' : ''}${
                this.user.placeName
            }`,
        });
    }

    private getDayAvailability(day: WeekDay) {
        return this.userAvailability?.[day];
    }
}
