import { AfterViewInit, Component, ElementRef, Input, ViewChild, ViewChildren } from '@angular/core';
import { RatingStarComponent } from 'app/components/common/rating-bar/rating-star/rating-star.component';

@Component({
    selector: 'rating-bar',
    templateUrl: './rating-bar.component.html',
    styleUrls: ['./rating-bar.component.less'],
})
export class RatingBarComponent implements AfterViewInit {
    @Input() color?: string;
    @Input() starsHasBorder?: boolean;
    @Input('rating') set rating(value: number) {
        this.ratingValue = !value || value < 0 || value > this.starsNumber ? 0 : value;
        this.updateRatingValue();
    }
    @Input('text') set text(value: string) {
        this.textValue = value ?? '';
        this.updateTextValue();
    }
    @Input() starSize?: number;
    @Input() textSize?: number;
    @Input() textColor?: string;

    starsNumber = 5;

    @ViewChild('text', { static: true }) private textElement?: ElementRef<HTMLSpanElement>;
    @ViewChildren(RatingStarComponent) private stars?: RatingStarComponent[];

    private ratingValue?: number;
    private textValue?: string;

    ngAfterViewInit() {
        if (this.color && this.textElement) {
            this.stars?.forEach(item => {
                item.foregroundColor = this.color ?? '';
            });
            this.textElement.nativeElement.style.color = this.color;
        }

        if (this.starsHasBorder) {
            this.stars?.forEach(item => {
                item.hasBorder = true;
            });
        }

        if (this.starSize) {
            this.stars?.forEach(item => {
                item.size = this.starSize ?? 0;
            });
            if (this.textElement) {
                this.textElement.nativeElement.style.fontSize = `${this.starSize}px`;
            }
        }

        if (this.textSize && this.textElement) {
            this.textElement.nativeElement.style.fontSize = `${this.textSize}px`;
        }

        if (this.textColor && this.textElement) {
            this.textElement.nativeElement.style.color = this.textColor;
        }

        this.updateRatingValue();
        this.updateTextValue();
    }

    private updateRatingValue() {
        if (this.stars) {
            this.stars.forEach((item, index) => {
                item.fulness = Math.max(0, 100 * Math.min(1, (this.ratingValue ?? 0) - index));
            });
        }
    }

    private updateTextValue() {
        if (this.textValue && this.textElement) {
            this.textElement.nativeElement.innerText = this.textValue;
        }
    }
}
