import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, inject } from '@angular/core';
import { FosterProperties, User } from 'app/models/api/user';
import { BaseComponent } from 'app/components/base.component';
import { HeaderListItem } from 'app/components/user-profile/user-profile-header/user-profile-tab-header.component';
import { UserApiService } from 'app/services/api/user.api.service';
import { formatDate } from '@angular/common';
import { Title } from '@angular/platform-browser';

type UserUpdate = Partial<User> &
    Partial<FosterProperties> & {
        availabilityPreference?: unknown;
        availability?: unknown;
    };

@Component({
    template: '',
})
export abstract class BaseUserProfileTabComponent extends BaseComponent implements OnChanges {
    @Input() user = new User();
    @Output() onUserUpdated = new EventEmitter<User>();
    headerItems: HeaderListItem[] = [];

    protected originalUser = new User();
    protected userApiService = inject(UserApiService);
    protected titleService = inject(Title);

    ngOnChanges(changes: SimpleChanges) {
        if (changes.user) {
            this.originalUser = this.user.deepCopy;
            this.onUserLoaded();
        }
    }

    protected onUserLoaded() {
        this.initHeaderItems();
        const { id, firstName, lastName } = this.user;
        const title = `${this.titleService.getTitle()} - ${firstName} ${lastName} - ${this.storageService.countryCode?.toUpperCase()}-${id}`;
        this.titleService.setTitle(title);
    }

    protected initHeaderItems() {
        const id = `${this.storageService.countryCode?.toUpperCase()}-${this.user?.id}`;
        this.headerItems = [
            {
                text: `ID: ${id}`,
                valueToCopy: id,
            },
            {
                text: `Status: ${this.user?.completed ? 'Completed' : 'Incomplete'} - 
                    ${this.user?.isPremium ? 'Premium' : "Basic member's"}
                    ${!this.user.active ? '- <strong>Blocked</strong>' : ''}
                    ${this.user.inappropriate ? ' - <strong>Quarantined</strong>' : ''}
                    ${this.user.suspected ? '- <strong>Suspected</strong>' : ''}`,
            },
            {
                text:
                    `Sign Up: ${formatDate(this.user.created ?? new Date(), 'dd-MM-YYYY', 'nl')}` +
                    ` - Last log in: ${formatDate(this.user.lastLogin ?? new Date(), 'dd-MM-YYYY', 'nl')}`,
            },
        ];

        if (this.user?.hasPublicProfile) {
            this.headerItems.splice(1, 0, {
                text: `URL: ${this.user.publicProfileUrl}`,
                valueToCopy: this.user.publicProfileUrl,
            });
        }
    }

    save(event: Event) {
        const input = event.target as HTMLInputElement;
        if (input) {
            let value;
            if (input.type === 'checkbox') {
                value = input.checked ? 1 : 0;
            } else {
                value = input.value;
            }

            if (value !== this.originalUser[input.name as keyof User]) {
                this.saveUserUpdate({ [input.name]: value });
            }
        }
    }

    saveUserUpdate(update: UserUpdate) {
        this.userApiService.updateUser(this.user.id, update).subscribe(
            res => {
                this.onUserUpdated.emit(res.data);
            },
            error => {
                this.onUserUpdated.emit(this.originalUser);
                console.log(error);
            },
        );
    }

    refreshUser() {
        return this.userApiService.getUser(this.user.id).subscribe(res => {
            this.onUserUpdated.emit(res.data);
        });
    }
}
