<div class="page-container">
    <div class="form">
        <div class="weights">
            <div *ngFor="let option of formOptions" class="weight-container">
                <label for="{{ option.key }}">{{ option.label }}</label>
                <input type="number" [(ngModel)]="weights[option.key]" />
            </div>
        </div>
        <div class="buttons-container">
            <button class="btn-bordered" (click)="setWeightsToDefault()">
                Restore
                <img image2x="redo" />
            </button>
            <button class="btn-bordered" (click)="copyWeights()">
                Copy all
                <img image2x="copy" />
            </button>
            <button class="btn-solid-bright-blue" (click)="performSearch()">Apply weights</button>
        </div>
    </div>

    <div class="matches-header">
        <button class="btn-link" [routerLink]="UserSubRouteType.profile">< Back to profile</button>
        <span class="matches-count">Matches: {{ totalCount ?? '-' }} {{ user.isParent ? 'babysitters' : 'parents' }}</span>
    </div>

    <ul>
        <li *ngFor="let match of matches" class="user-card">
            <div class="user-info-container">
                <img class="avatar" avatar [user]="match" [size]="200" />
                <div class="user-info">
                    <span>{{ match.firstName }} ({{ match.age }})</span>
                    <rating-bar
                        *ngIf="match.averageRecommendationScore"
                        color="#fbbf24"
                        textColor="#19262d"
                        [starSize]="14"
                        [rating]="match.averageRecommendationScore"
                        [text]="match.recommendations.length + ' reviews'"
                    >
                        ></rating-bar
                    >
                    <span>{{ mainInfoForUser(match) }}</span>
                    <span *ngIf="!match.isParent">{{ yearsExprerience(match) }}</span>
                    <span>{{ match.photos.length }} photos</span>
                </div>
                <div class="comparison">
                    <div class="comparison-line header">
                        <div class="comparison-line-item">{{ user.isParent ? 'Sitter' : 'Parent' }}</div>
                        <div class="comparison-line-item">{{ user.isParent ? 'Parent' : 'Sitter' }}</div>
                    </div>
                    <div *ngFor="let item of comparisonLinesMainUser; index as i" class="comparison-line">
                        <div class="comparison-line-item" [innerHtml]="match.comparisonLines[i]"></div>
                        <div class="comparison-line-item" [innerHtml]="item"></div>
                    </div>
                </div>
            </div>
            <div class="stats">
                <div class="stats-item match">{{ match.meta?.relevanceSortingStats?.match | number: '1.0-0' }}%</div>
                <div *ngFor="let stat of match.weightsStats" class="stats-item" [class.highlighted]="stat.highlighted">
                    <div class="weight-value">{{ stat.value | number: '1.0-0' }}</div>
                    <div class="weight-name">{{ stat.name }}</div>
                </div>
            </div>
        </li>
    </ul>
</div>
