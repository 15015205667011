import { WarningLevel } from 'app/models/api/warning';
import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'app/components/base.component';
import { User, UserRole } from 'app/models/api/user';
import { SearchParams, UserApiService } from 'app/services/api/user.api.service';
import { UserService } from 'app/services/user.service';
import { format } from 'date-fns';
import { Util } from 'app/utils/utils';
import { PSPType } from 'app/models/api/payment';

export enum FilterType {
    role = 'role',
    payment = 'payment',
    more = 'more',
}

@Component({
    selector: 'sitly-users',
    templateUrl: './sitly-users.component.html',
    styleUrls: ['./sitly-users.component.less'],
})
export class SitlyUsersComponent extends BaseComponent {
    roleFilters = {
        babysitter: false,
        parent: false,
        childminder: false,
    };
    paymentPlatformFilters = {
        adyen: false,
        apple: false,
        google: false,
    };
    otherFilters = {
        blocked: false,
        quarantined: false,
        hidden: false,
        male: false,
        premium: false,
    };

    showMoreFiltersOptions = false;
    showPaymentPlatformFilterOptions = false;
    showRoleFilterOptions = false;

    searchForm = new FormGroup({
        email: new FormControl('', {
            validators: [Validators.minLength(4)],
            updateOn: 'submit',
        }),
        placeUrl: new FormControl('', {}),
        firstName: new FormControl('', {}),
        lastName: new FormControl('', {}),
        webuserUrl: new FormControl('', {}),
    });
    get roleFiltersCount() {
        return Util.keysOf(this.roleFilters).reduce((prev, current) => (prev += this.roleFilters[current] ? 1 : 0), 0);
    }
    get paymentFiltersCount() {
        return Util.keysOf(this.paymentPlatformFilters).reduce(
            (prev, current) => (prev += this.paymentPlatformFilters[current] ? 1 : 0),
            0,
        );
    }
    get moreFiltersCount() {
        return Util.keysOf(this.otherFilters).reduce((prev, current) => (prev += this.otherFilters[current] ? 1 : 0), 0);
    }

    showError = false;
    users?: User[];

    FilterType = FilterType;
    WarningLevel = WarningLevel;

    private userApiService = inject(UserApiService);
    private userService = inject(UserService);

    ngOnInit() {
        Object.values(this.searchForm.controls).forEach(control => {
            control.valueChanges.subscribe(() => (this.showError = false));
        });
    }

    searchUsers() {
        const values = this.searchForm.getRawValue() as object;
        if (!this.searchForm.valid || !Object.values(values).some(item => item)) {
            this.showError = true;
            return;
        }

        const searchParams: Partial<SearchParams> = {};
        Util.entries(values).forEach(([key, value]) => {
            if (value) {
                searchParams[key] = value;
            }
        });

        const roles = Util.keysOf(this.roleFilters)
            .map(key => {
                return this.roleFilters[key] ? key : '';
            })
            .filter((item): item is UserRole => !!item);
        if (roles.length > 0) {
            searchParams.roles = roles;
        }

        const paymentPlatforms = Util.keysOf(this.paymentPlatformFilters)
            .map(key => {
                return this.paymentPlatformFilters[key] ? key : '';
            })
            .filter((item): item is PSPType => !!item);
        if (paymentPlatforms.length > 0) {
            searchParams.paymentPlatforms = paymentPlatforms;
        }

        Util.keysOf(this.otherFilters).forEach(key => {
            if (this.otherFilters[key]) {
                searchParams[key] = this.otherFilters[key];
            }
        });

        this.userApiService.searchUsers(searchParams).subscribe(response => {
            this.users = response.data;
        });
    }

    onFilterClicked(filter: FilterType) {
        if (filter === FilterType.role) {
            this.showRoleFilterOptions = !this.showRoleFilterOptions;
        }
        if (filter === FilterType.payment) {
            this.showPaymentPlatformFilterOptions = !this.showPaymentPlatformFilterOptions;
        }
        if (filter === FilterType.more) {
            this.showMoreFiltersOptions = !this.showMoreFiltersOptions;
        }
    }

    closeFilterOptions(filter: FilterType) {
        if (filter === FilterType.role && this.showRoleFilterOptions) {
            this.showRoleFilterOptions = false;
        }
        if (filter === FilterType.payment && this.showPaymentPlatformFilterOptions) {
            this.showPaymentPlatformFilterOptions = false;
        }
        if (filter === FilterType.more && this.showMoreFiltersOptions) {
            this.showMoreFiltersOptions = false;
        }
    }

    createUser(role: string) {
        const { email, firstName, lastName } = this.gemUser;
        const countryCode = this.storageService.countryCode as string;

        const suffix = format(new Date(), 'yyyy_MMdd_HHmmss');
        const newUserEmail = `${email.split('@')[0]}+${suffix}@sitly.com`;

        this.userService
            .createUser({ email: newUserEmail, firstName, lastName, role, countryCode })
            .subscribe(response => this.userService.loginAsUser(response.id));
    }

    resetFormFilters() {
        this.roleFilters = {
            babysitter: false,
            parent: false,
            childminder: false,
        };
        this.paymentPlatformFilters = {
            adyen: false,
            apple: false,
            google: false,
        };
        this.otherFilters = {
            blocked: false,
            quarantined: false,
            hidden: false,
            male: false,
            premium: false,
        };
    }
}
