import { RouteType } from 'app/routing/route-type';
import { RouteReuseStrategy, DetachedRouteHandle, ActivatedRouteSnapshot } from '@angular/router';

export class CacheRouteReuseStrategy implements RouteReuseStrategy {
    private storedRouteHandles = new Map<string, DetachedRouteHandle>();
    private cachingRoutesPaths = [`:country/${RouteType.users}`];

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.routeConfig === curr.routeConfig;
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        return this.storedRouteHandles.get(this.getPath(route)) ?? null;
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        const path = this.getPath(route);
        if (this.cachingRoutesPaths.includes(path)) {
            return this.storedRouteHandles.has(path);
        }

        return false;
    }

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return this.cachingRoutesPaths.includes(this.getPath(route));
    }

    store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
        this.storedRouteHandles.set(this.getPath(route), detachedTree);
    }

    private getPath(route: ActivatedRouteSnapshot): string {
        return route.routeConfig?.path ?? '';
    }
}
