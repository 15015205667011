import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'std',
})
export class StringToDatePipe implements PipeTransform {
    transform(input?: string): Date | null {
        return input ? new Date(input) : null;
    }
}
