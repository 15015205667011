import { Message, MessageAction, MessageType } from 'app/models/api/message';
import { User } from 'app/models/api/user';
import { ParsedResponse } from 'app/models/api/response';
import { differenceInCalendarDays, differenceInSeconds, format } from 'date-fns';

class MessagesGroup {
    messages: Message[] = [];
    get showLogoAvatar() {
        return this.isSafetyTips || this.isAutorejectionMessage;
    }

    constructor(
        public date: Date,
        public formattedDate: string,
        public isSafetyTips: boolean,
        public isAutorejectionMessage: boolean,
        public action?: MessageAction,
    ) {}

    static messagesGroups(messages: Message[]) {
        const parsedConversation: MessagesGroup[] = [];
        let lastMessage: Message | undefined;
        let group: MessagesGroup | undefined;

        for (let i = messages.length - 1; i >= 0; i--) {
            const message = messages[i];
            const diff = lastMessage ? differenceInSeconds(message.date, lastMessage.date) : 0;

            const newGroup =
                !lastMessage || diff > 180 || message.action !== lastMessage.action || message.link || message.type !== lastMessage.type;
            if (newGroup) {
                group = new MessagesGroup(
                    message.date,
                    MessagesGroup.formatDate(message.date),
                    message.isSafetyTips,
                    message.isAutorejection,
                    message.action,
                );
                parsedConversation.push(group);
            }
            group?.messages.push(message);

            lastMessage = message;
        }

        return parsedConversation;
    }

    private static formatDate(date: Date) {
        const now = new Date();
        const diffInDays = differenceInCalendarDays(now, date);

        if (diffInDays === 0) {
            return 'today';
        } else if (diffInDays === 1) {
            return 'yesterday';
        }

        return format(date, 'd MMMM yyyy');
    }
}

export class ChatModel {
    user = new User();
    messages: Message[] = [];
    parsedConversation: MessagesGroup[] = [];
    chatPartner = new User();
    chatPartnerChoresString = '';
    chatPartnerAvailabilityString = '';
    unreadMessagesCount = 0;

    isLoaded = true;
    canChat = false;
    canAskForRecommendations = false;
    askDisableSafetyMessages = false;
    safetyTips = '';
    optionsVisible = false;
    safetyTipsVisible = false;
    fairUsePolicyPromptExpanded = false;
    rateLimitExceeded = false;
    inviteToApplyUsed = false;

    get showAutoReject(): boolean {
        return this._showAutoreject && !this.chatPartnerDisabled;
    }
    get canInvite() {
        return this.chatPartner?.canBeInvitedToApply && !this.inviteToApplyUsed;
    }

    get oldestMessageTime() {
        return this.messages[this.messages.length - 1]?.date;
    }

    get newestMessageTimeString() {
        return this.messages[0]?.date;
    }

    get newestMessageId(): string {
        return this.messages[0]?.id;
    }

    get isEmpty(): boolean {
        return this.messages.length === 0 || !this.messages.some(item => !item.type || item.type === MessageType.regular);
    }

    get chatPartnerDisabled(): boolean {
        return !(this.chatPartner?.availableForChat ?? true);
    }

    get showNonResponderWarning() {
        return !this.chatPartnerDisabled && this.user.isPremium && this.chatPartner?.isPotentialNonResponder;
    }

    get showChatInput() {
        return !this.showFairUsePolicyPrompt && !this.chatPartnerDisabled;
    }
    get showFairUsePolicyPrompt() {
        return this.user.isPremium && !this.chatPartnerDisabled && this.rateLimitExceeded;
    }
    private _showAutoreject = false;

    avatar(action: MessageAction) {
        if (action === MessageAction.received) {
            return this.chatPartner.links?.avatar;
        } else {
            return this.user.links?.avatar;
        }
    }

    groupHeader(group: MessagesGroup) {
        if (group.isSafetyTips) {
            return 'An important message from Sitly:';
        } else if (group.isAutorejectionMessage) {
            return 'A message from Sitly';
        }
        return null;
    }

    senderFirstName(group: MessagesGroup) {
        if (group.action === MessageAction.received) {
            return this.chatPartner.firstName;
        } else {
            return this.user.firstName;
        }
    }

    pushMessages(messages: Message[], pushToStart = false) {
        if (!messages || messages.length === 0) {
            return;
        }

        if (pushToStart) {
            this.messages.unshift(...messages);
        } else {
            this.messages.push(...messages);
        }
        this.parsedConversation = MessagesGroup.messagesGroups(this.messages);
        this._showAutoreject =
            this.messages.length > 0 &&
            !this.messages.some(item => item.meta?.action === MessageAction.sent) &&
            this.messages[0].type !== MessageType.instantJob;
    }

    pushMessageIfNeeded(message: Message) {
        if (!this.messages.some(element => element.id === message.id)) {
            this.pushMessages([message], true);
        }
    }

    updateWithServerResponse(response: ParsedResponse<Message[]>, pushToStart = false) {
        this.pushMessages(response.data, pushToStart);
        if (this.user.isPremium || !this.isEmpty) {
            this.canChat = true;
        }
    }
}
