import { BaseApiModel } from 'app/models/api/response';
import { Message } from 'app/models/api/message';
import { Photo } from 'app/models/api/photo';

export enum WarningLevel {
    moderate = 'moderate',
    severe = 'severe',
    blocked = 'blocked',
    ignored = 'ignored',
    suspected = 'suspected',
}

export enum WarningType {
    avatar = 'avatar',
    male = 'male',
    message = 'message',
    report = 'report',
    spam = 'spam',
}
export class Warning extends BaseApiModel {
    warningLevel: WarningLevel = WarningLevel.moderate;
    warningType?: WarningType;
    warningText?: string;
    warningPhrases?: string;
    message?: Message;
    photo?: Photo;
}
