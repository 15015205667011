import { Component, inject } from '@angular/core';
import { GemSelectCheckableOption } from 'app/components/common/gem-multi-select/gem-multi-select.component';
import { GemSelectOption } from 'app/components/common/gem-select/gem-select.component';
import { SensitivePhrase, SensitivePhraseType } from 'app/models/api/sensitive-phrase';
import { SensitivePhraseApiService, SensitivePhraseFilter } from 'app/services/api/sensitive-phrase.api.service';
import { BaseSensitivePhrasesComponent } from 'app/components/screening/base-phrases-page.component';

@Component({
    selector: 'sensitive-phrases',
    templateUrl: './sensitive-phrases.component.html',
    styleUrls: ['./sensitive-phrases.component.less'],
})
export class ScreeningSensitivePhrasesComponent extends BaseSensitivePhrasesComponent<SensitivePhrase> {
    phraseTypeOptions: GemSelectOption<string>[] = [
        { id: SensitivePhraseType.moderate, label: 'Moderate' },
        { id: SensitivePhraseType.severe, label: 'Severe' },
    ];
    filterOptions: GemSelectCheckableOption[] = [
        { id: SensitivePhraseType.moderate, label: 'Moderate', checked: false },
        { id: SensitivePhraseType.severe, label: 'Severe', checked: false },
    ];

    private sensitivePhrasesService = inject(SensitivePhraseApiService);

    getItems(pageNumber: number, pageSize: number) {
        return this.sensitivePhrasesService.getPhrases(pageNumber, pageSize, this.getSearchParams());
    }

    createNewItem() {
        return new SensitivePhrase();
    }

    postNewItem(phrase: string, type?: SensitivePhraseType) {
        return this.sensitivePhrasesService.createPhrase(phrase, type as SensitivePhraseType);
    }

    updateItem(itemId: string, phrase?: string, type?: SensitivePhraseType) {
        return this.sensitivePhrasesService.updatePhrase(itemId, phrase, type as SensitivePhraseType);
    }

    deleteItems(ids: string[]) {
        return this.sensitivePhrasesService.deletePhrases(ids);
    }

    private getSearchParams() {
        if (this.model.filters?.length === 0 && !this.model.query) {
            return undefined;
        }

        const params: SensitivePhraseFilter = {};
        if (this.model.filters?.length ?? 0 > 0) {
            params.types = this.model.filters;
        }
        if (this.model.query) {
            params.phrase = this.model.query;
        }
        return params;
    }
}
