<div class="side-menu-container" [class.collapsed]="collapsed()">
    <button class="btn-collapse" (click)="toggleMenu()">
        <img [image2x]="collapsed() ? 'expand' : 'collapse'" />
    </button>

    <img class="logo" image2x="logo-rounded" />

    <select
        *ngIf="!isTranslationsRoute()"
        name="countries"
        id="countries"
        class="country-select"
        [ngModel]="storageService.countryCode"
        (ngModelChange)="sessionService.switchCountry($event)"
    >
        <option *ngFor="let option of gemUser.countries" [ngValue]="option.countryCode">
            {{ collapsed() ? option.countryCode.toUpperCase() : option.name }}
        </option>
    </select>

    <div class="medium-container">
        <button
            *ngIf="canAccessSitlyUsers"
            class="menu-link filled"
            [class.center-content]="collapsed()"
            title="Screening"
            [class.active]="isScreeningRoute()"
            [class.indicator]="collapsed()"
            (click)="navigationService.navigateTo(RouteType.screening)"
        >
            <img class="highlightable" image2x="side-menu/screening" />
            <span *ngIf="!collapsed()" class="label">Screening</span>
            <span class="counter" *ngIf="!collapsed() && warnedUsersCount > 0">{{ warnedUsersCount }}</span>
        </button>

        <div class="submenu" *ngIf="canAccessSitlyUsers && !collapsed() && isScreeningRoute()">
            <button class="sub-menu-link" [class.active]="isScreeningGeneralRoute()" [routerLink]="screeningGeneralRouterLink">
                General
            </button>
            <button class="sub-menu-link" [class.active]="isScreeningPersonalDataRoute()" [routerLink]="screeningPersonalDataRouterLink">
                Personal data
                <span class="counter" *ngIf="!collapsed() && usersWithPersonalDataCount > 0">{{ usersWithPersonalDataCount }}</span>
            </button>
            <button
                class="sub-menu-link"
                [class.active]="isScreeningSensitivePhrasesRoute()"
                [routerLink]="screeningSensitivePhrasesRouterLink"
            >
                Phrases
            </button>
            <button class="sub-menu-link" [class.active]="isScreeningExclusionsRoute()" [routerLink]="screeningExclusionsRouterLink">
                Exclusions
            </button>
        </div>

        <button
            *ngIf="canAccessSitlyUsers"
            class="menu-link filled indicator"
            title="Sitly users"
            [class.center-content]="collapsed()"
            [class.active]="isUsersRoute()"
            (click)="navigationService.navigateTo(RouteType.users)"
        >
            <img class="highlightable" image2x="side-menu/user" />
            <span *ngIf="!collapsed()" class="label">Sitly users</span>
        </button>

        <button
            class="menu-link filled"
            *ngIf="canAccessTranslations"
            title="Translations"
            [class.center-content]="collapsed()"
            [class.active]="isTranslationsRoute()"
            (click)="navigationService.navigateTo(RouteType.translations)"
        >
            <img class="highlightable" image2x="side-menu/translations" />
            <span *ngIf="!collapsed()" class="label">Translations</span>
        </button>

        <div class="submenu" *ngIf="canAccessTranslations && !collapsed() && isTranslationsRoute()">
            <button class="sub-menu-link" [class.active]="isTranslationsTranslateRoute()" [routerLink]="translationsTranslateRouterLink">
                Translate
            </button>
            <button
                *ngIf="gemUser.role === GemUserRole.admin"
                class="sub-menu-link"
                [class.active]="isTranslationsAddRoute()"
                [routerLink]="translationsAddRouterLink"
            >
                Add
            </button>
            <button
                *ngIf="gemUser.role === GemUserRole.admin && isProd"
                class="sub-menu-link"
                [class.active]="isTranslationsPublishRoute()"
                [routerLink]="translationsPublishRouterLink"
            >
                Publish
            </button>
            <button
                *ngIf="gemUser.role === GemUserRole.admin"
                class="sub-menu-link"
                [class.active]="isTranslationsGroupsRoute()"
                [routerLink]="translationsGroupsRouterLink"
            >
                Groups
            </button>
        </div>

        <button
            *ngIf="gemUser.role === GemUserRole.admin"
            class="menu-link filled indicator"
            title="Subscriptions"
            [class.center-content]="collapsed()"
            [class.active]="isSubscriptionsRoute()"
            (click)="navigationService.navigateTo(RouteType.subscriptions)"
        >
            <img class="highlightable" image2x="side-menu/subscription" />
            <span *ngIf="!collapsed()" class="label">Subscriptions</span>
        </button>

        <button
            *ngIf="gemUser.role === GemUserRole.admin || gemUser.role === GemUserRole.customerResearcher"
            class="menu-link filled"
            title="User feedback"
            [class.active]="isFeedbacksRoute()"
            [class.center-content]="collapsed()"
            (click)="navigationService.navigateTo(RouteType.feedbacks)"
        >
            <img class="highlightable" image2x="side-menu/thumbs-up" />
            <span *ngIf="!collapsed()" class="label">User feedback</span>
        </button>
    </div>

    <div class="bottom-container">
        <button
            *ngIf="gemUser.role === GemUserRole.admin"
            class="menu-link indicator"
            [class.center-content]="collapsed()"
            [class.active]="isSettingsRoute()"
            title="Settings"
            (click)="navigationService.navigateTo(RouteType.settings)"
        >
            <img [image2x]="isSettingsRoute() ? 'side-menu/settings-active' : 'side-menu/settings'" />
            <span *ngIf="!collapsed()" class="label">Settings</span>
        </button>

        <button class="menu-link" [class.center-content]="collapsed()" title="Logout" (click)="sessionService.signOut()">
            <img image2x="side-menu/logout" />
            <span *ngIf="!collapsed()" class="label">Logout</span>
        </button>
    </div>
</div>
