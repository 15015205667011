import { Component } from '@angular/core';
import { BaseUserProfileTabComponent } from 'app/components/user-profile/base-user-profile-tab.component';
import { PaymentType, PSPType } from 'app/models/api/payment';
import { format } from 'date-fns';

@Component({
    selector: 'subscription-tab',
    templateUrl: './subscription-tab.component.html',
    styleUrls: ['./subscription-tab.component.less'],
})
export class SubscriptionTabComponent extends BaseUserProfileTabComponent {
    gracePeriod = '';
    premiumExpiryDate = '';

    onUserLoaded() {
        super.onUserLoaded();
        this.gracePeriod = this.user.gracePeriod ? format(new Date(this.user.gracePeriod), 'yyyy-MM-dd') : '';
        this.premiumExpiryDate = this.user.premiumExpiryDate ? format(new Date(this.user.premiumExpiryDate), 'yyyy-MM-dd') : '';
    }

    protected initHeaderItems() {
        super.initHeaderItems();
        if (this.user.payments.length > 0) {
            const lastPayment = this.user.payments[0];
            const paymentId =
                lastPayment.psp !== PSPType.google
                    ? `${this.storageService.countryCode?.toUpperCase()}-${lastPayment.id}`
                    : lastPayment.pspReference;
            this.headerItems.push({
                text: `Payment ID: ${paymentId}`,
                valueToCopy: paymentId,
            });
            this.headerItems.push({
                text: `Last initial payment: ${
                    this.user.payments.find(item => item.orderType === PaymentType.initial)?.ddMMyyyyCreated ?? '-'
                }`,
            });
            this.headerItems.push({
                text: `Last recurring payment: ${
                    this.user.payments.find(item => item.orderType === PaymentType.recurring)?.ddMMyyyyCreated ?? '-'
                }`,
            });
        }
    }
}
