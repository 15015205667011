<div class="container">
    <div class="top-bar">
        <div class="select-container">
            <div class="header">Language</div>
            <div class="header">Source</div>
            <div class="header">Target</div>

            <select name="locale-id" id="locale-id" [(ngModel)]="localeId" (change)="refresh()">
                <option *ngFor="let locale of localeOptions" id="{{ locale.code }}" value="{{ locale.id }}">
                    {{ locale.name }}
                </option>
            </select>

            <select name="source-environment" id="source-environment" [(ngModel)]="sourceEnvironment" (change)="refresh()">
                <option *ngFor="let env of environmentOptions" id="{{ env }}" value="{{ env }}">
                    {{ env | titlecase }}
                </option>
            </select>

            <select name="target-environment" id="target-environment" [(ngModel)]="targetEnvironment" (change)="refresh()">
                <option *ngFor="let env of environmentOptions" id="{{ env }}" value="{{ env }}">
                    {{ env | titlecase }}
                </option>
            </select>
        </div>

        <div class="buttons-container">
            <button class="btn-solid-green" (click)="toggleAllSelectionState()">{{ allSelected ? 'Unselect all' : 'Select all' }}</button>
            <button class="btn-solid-hibiscus" [disabled]="loading" (click)="publish()">Publish</button>
        </div>
    </div>

    <div class="translations-container">
        <div class="translation-header">
            <div>Translation Code</div>
            <div>Group</div>
            <div>{{ sourceEnvironment | titlecase }}</div>
            <div>{{ targetEnvironment | titlecase }}</div>
        </div>
        <div class="translation-item" *ngFor="let item of translations">
            <div><input type="checkbox" [(ngModel)]="item.selected" /></div>
            <div class="translation-code">{{ item.translation.translationCode }}</div>
            <div>{{ item.translation.groupName }}</div>
            <div>{{ item.translation | envValue: sourceEnvironment }}</div>
            <div>{{ item.translation | envValue: targetEnvironment }}</div>
        </div>
    </div>

    <div *ngIf="totalCount > 0">
        <mat-paginator
            [length]="totalCount"
            [pageSize]="pageSize"
            [pageSizeOptions]="[10, 25, 50, 100]"
            [pageIndex]="pageNumber - 1"
            (page)="onPageChanged($event)"
            showFirstLastButtons="true"
            aria-label="Select page"
        >
        </mat-paginator>
    </div>
</div>
