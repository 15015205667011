import { Injectable } from '@angular/core';
import { SensitivePhraseMeta } from 'app/models/api/sensitive-phrase';
import { ApiService, ParamsMapValue } from 'app/services/api/api.service';
import { map } from 'rxjs/operators';
import { ResponseParser } from 'app/services/api/parsers/response-parser';
import { SensitivePhraseExclusion } from 'app/models/api/sensitive-phrase-exclusion';

export interface SensitivePhraseExclusionFilter {
    phrase?: string;
}

@Injectable({
    providedIn: 'root',
})
export class SensitivePhraseExclusionApiService {
    constructor(
        private apiService: ApiService,
        private responseParser: ResponseParser,
    ) {}

    getExclusions(pageNumber = 1, pageSize = 40, filter?: SensitivePhraseExclusionFilter) {
        return this.apiService
            .get('/gem/sensitive-phrase-exclusions', {
                params: {
                    page: { size: pageSize, number: pageNumber },
                    filter: filter as ParamsMapValue,
                },
            })
            .pipe(map(response => this.responseParser.parseObject<SensitivePhraseExclusion[], SensitivePhraseMeta>(response)));
    }

    createExclusion(phrase: string) {
        return this.apiService
            .post('/gem/sensitive-phrase-exclusions', {
                body: {
                    action: 'create',
                    payload: {
                        phrase,
                    },
                },
            })
            .pipe(map(response => this.responseParser.parseObject<SensitivePhraseExclusion>(response)));
    }

    deleteExclusions(ids: string[]) {
        return this.apiService.post('/gem/sensitive-phrase-exclusions', {
            body: {
                action: 'delete',
                payload: {
                    ids: ids.map(item => parseInt(item, 0)),
                },
            },
        });
    }

    updateExclusion(phraseId: string, phrase?: string) {
        return this.apiService
            .patch(`/gem/sensitive-phrase-exclusions/${phraseId}`, {
                body: {
                    phrase,
                },
            })
            .pipe(map(response => this.responseParser.parseObject<SensitivePhraseExclusion>(response)));
    }
}
