import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BaseComponent } from 'app/components/base.component';
import { ScreeningSubRouteType } from 'app/routing/route-type';
import { Subscription } from 'rxjs';

@Component({
    selector: 'screening',
    templateUrl: './screening.component.html',
    styleUrls: ['./screening.component.less'],
})
export class ScreeningComponent extends BaseComponent implements OnInit, OnDestroy {
    selectedTab?: ScreeningSubRouteType;
    ScreeningSubRouteType = ScreeningSubRouteType;

    private urlSubscription?: Subscription;
    private router = inject(Router);

    ngOnInit() {
        this.selectedTab = this.getTabFromUrl(this.router.url);

        this.urlSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.selectedTab = this.getTabFromUrl(event.url);
                // this.cd.markForCheck();
            }
        });
    }

    ngOnDestroy() {
        this.urlSubscription?.unsubscribe();
    }

    private getTabFromUrl(url: string) {
        const urlParts = url.split('/');
        return urlParts[urlParts.length - 1] as ScreeningSubRouteType;
    }
}
