import { Component, inject } from '@angular/core';
import { SensitivePhraseExclusion } from 'app/models/api/sensitive-phrase-exclusion';
import { SensitivePhraseExclusionApiService } from 'app/services/api/sensitive-phrase-exclusion.api.service';
import { BaseSensitivePhrasesComponent } from 'app/components/screening/base-phrases-page.component';

export class SelectableExclusion {
    selected = false;
    editMode = false;

    constructor(public exclusion: SensitivePhraseExclusion) {}
}

@Component({
    selector: 'exclusions',
    templateUrl: './exclusions.component.html',
    styleUrls: ['./exclusions.component.less'],
})
export class ScreeningExclusionsComponent extends BaseSensitivePhrasesComponent<SensitivePhraseExclusion> {
    private sensitivePhraseExclusionsService = inject(SensitivePhraseExclusionApiService);

    getItems(pageNumber: number, pageSize: number) {
        return this.sensitivePhraseExclusionsService.getExclusions(pageNumber, pageSize, this.getSearchParams());
    }

    createNewItem() {
        return new SensitivePhraseExclusion();
    }

    postNewItem(phrase: string) {
        return this.sensitivePhraseExclusionsService.createExclusion(phrase);
    }

    updateItem(itemId: string, phrase?: string) {
        return this.sensitivePhraseExclusionsService.updateExclusion(itemId, phrase);
    }

    deleteItems(ids: string[]) {
        return this.sensitivePhraseExclusionsService.deleteExclusions(ids);
    }

    private getSearchParams() {
        if (!this.model.query) {
            return undefined;
        }

        return {
            phrase: this.model.query,
        };
    }
}
