<div class="page-container">
    <form [formGroup]="searchForm" class="search-form" (submit)="searchUsers()">
        <div class="input-grid">
            <input type="email" formControlName="email" placeholder="Email" />
            <input type="text" formControlName="placeUrl" placeholder="Place" />
            <input type="text" formControlName="firstName" placeholder="First name" />
            <input type="text" formControlName="lastName" placeholder="Last name" />
            <input type="text" formControlName="webuserUrl" placeholder="Profile url" class="profile-url" />
        </div>

        <div class="filters">
            <div class="filter-container" (clickOutside)="closeFilterOptions(FilterType.role)">
                <button type="button" class="btn-transparent btn-options btn-filter" (click)="onFilterClicked(FilterType.role)">
                    Roles <span *ngIf="roleFiltersCount > 0" class="filter-count">{{ roleFiltersCount }}</span>
                </button>
                <div class="filter-options role-options" [class.visible]="showRoleFilterOptions">
                    <div class="option">
                        <input
                            type="checkbox"
                            id="cb-parent"
                            name="cb-parent"
                            [(ngModel)]="roleFilters.parent"
                            [ngModelOptions]="{ standalone: true }"
                        />
                        <label for="cb-parent">Parents</label>
                    </div>
                    <div class="option">
                        <input
                            type="checkbox"
                            id="cb-babysitter"
                            name="cb-babysitter"
                            [(ngModel)]="roleFilters.babysitter"
                            [ngModelOptions]="{ standalone: true }"
                        />
                        <label for="cb-babysitter">Babysitters</label>
                    </div>
                    <div class="option">
                        <input
                            type="checkbox"
                            id="cb-childminder"
                            name="cb-childminder"
                            [(ngModel)]="roleFilters.childminder"
                            [ngModelOptions]="{ standalone: true }"
                        />
                        <label for="cb-childminder">Childminders</label>
                    </div>
                </div>
            </div>

            <div class="filter-container" (clickOutside)="closeFilterOptions(FilterType.payment)">
                <button type="button" class="btn-transparent btn-options btn-filter" (click)="onFilterClicked(FilterType.payment)">
                    Payment options <span *ngIf="paymentFiltersCount > 0" class="filter-count">{{ paymentFiltersCount }}</span>
                </button>
                <div class="filter-options payment-options" [class.visible]="showPaymentPlatformFilterOptions">
                    <div class="option">
                        <input
                            type="checkbox"
                            id="cb-web"
                            name="cb-web"
                            [(ngModel)]="paymentPlatformFilters.adyen"
                            [ngModelOptions]="{ standalone: true }"
                        />
                        <label for="cb-web">Web</label>
                    </div>
                    <div class="option">
                        <input
                            type="checkbox"
                            id="cb-apple"
                            name="cb-apple"
                            [(ngModel)]="paymentPlatformFilters.apple"
                            [ngModelOptions]="{ standalone: true }"
                        />
                        <label for="cb-apple">Apple</label>
                    </div>
                    <div class="option">
                        <input
                            type="checkbox"
                            id="cb-android"
                            name="cb-android"
                            [(ngModel)]="paymentPlatformFilters.google"
                            [ngModelOptions]="{ standalone: true }"
                        />
                        <label for="cb-android">Android</label>
                    </div>
                </div>
            </div>

            <div class="filter-container" (clickOutside)="closeFilterOptions(FilterType.more)">
                <button type="button" class="btn-transparent btn-options btn-filter" (click)="onFilterClicked(FilterType.more)">
                    More filters <span *ngIf="moreFiltersCount > 0" class="filter-count">{{ moreFiltersCount }}</span>
                </button>
                <div class="filter-options more-options" [class.visible]="showMoreFiltersOptions">
                    <div class="option">
                        <input
                            type="checkbox"
                            id="cb-blocked"
                            name="cb-blocked"
                            [(ngModel)]="otherFilters.blocked"
                            [ngModelOptions]="{ standalone: true }"
                        />
                        <label for="cb-blocked">Blocked</label>
                    </div>
                    <div class="option">
                        <input
                            type="checkbox"
                            id="cb-quarantined"
                            name="cb-quarantined"
                            [(ngModel)]="otherFilters.quarantined"
                            [ngModelOptions]="{ standalone: true }"
                        />
                        <label for="cb-quarantined">Quarantined</label>
                    </div>
                    <div class="option">
                        <input
                            type="checkbox"
                            id="cb-hidden"
                            name="cb-hidden"
                            [(ngModel)]="otherFilters.hidden"
                            [ngModelOptions]="{ standalone: true }"
                        />
                        <label for="cb-hidden">Hidden</label>
                    </div>
                    <div class="option">
                        <input
                            type="checkbox"
                            id="cb-male"
                            name="cb-male"
                            [(ngModel)]="otherFilters.male"
                            [ngModelOptions]="{ standalone: true }"
                        />
                        <label for="cb-male">Male</label>
                    </div>
                    <div class="option">
                        <input
                            type="checkbox"
                            id="cb-premium"
                            name="cb-premium"
                            [(ngModel)]="otherFilters.premium"
                            [ngModelOptions]="{ standalone: true }"
                        />
                        <label for="cb-premium">Premium</label>
                    </div>
                </div>
            </div>
        </div>

        <button type="submit" class="btn-solid-blue btn-search">
            <img image2x="search-icon" />
            Search users
        </button>

        <button class="btn-link" type="reset" (click)="resetFormFilters()">Clear form</button>

        <button class="btn-link" type="button" (click)="createUser('parent')">Create test parent</button>

        <button class="btn-link" type="button" (click)="createUser('babysitter')">Create test babysitter</button>
    </form>

    <div class="error" *ngIf="showError">Fill in at least one search term</div>

    <table class="user-table" *ngIf="users?.length ?? 0 > 0">
        <thead>
            <th class="avatar"></th>
            <th>User</th>
            <th>Place</th>
            <th>Address</th>
            <th>Dates</th>
            <th>Status</th>
            <th>Notes</th>
        </thead>
        <tbody>
            <a *ngFor="let user of users" [routerLink]="user.id" target="_blank">
                <td class="col-role">
                    <div
                        [class.parent]="user.isParent"
                        [class.childminder]="user.isChildminder"
                        [class.babysitter]="user.isBabysitter"
                    ></div>
                </td>
                <td class="col-user">
                    <div class="name">{{ user.firstName }} {{ user.lastName }} {{ user.isMale ? '(M)' : '(F)' }}</div>
                    <div class="email">{{ user.email }}</div>
                </td>
                <td class="col-place">{{ user.placeName }}</td>
                <td class="col-address">{{ user.streetName }}</td>
                <td class="col-dates">
                    <div class="date">Sign-up: {{ user.created | std | dfnsFormat: 'dd-MM-yy' }}</div>
                    <div class="date">Log in: {{ user.lastLogin | std | dfnsFormat: 'dd-MM-yy' }}</div>
                </td>
                <td class="col-status">
                    <img
                        class="status-img"
                        [image2x]="
                            'user-screening-icons/' +
                            (user.statusRedFlag ? 'black-listed' : user.statusYellowFlag ? 'grey-listed' : 'not-flagged')
                        "
                    />
                    <div class="status-text">
                        <div>{{ user.completed ? 'Completed' : 'Incomplete' }}</div>
                        <div>{{ user.isPremium ? 'Premium' : 'Basic member' }}</div>
                        <div *ngIf="user.disabled">Hidden</div>
                        <div *ngIf="user.inappropriate">Quarantined</div>
                        <div *ngIf="!user.active">Blocked</div>
                        <div *ngIf="user.suspected">Suspected</div>
                        <div *ngIf="!!user.notes">Notes/warnings</div>
                    </div>
                </td>
                <td class="col-notes" [class.empty]="!user.notes">
                    <div>{{ user.notes }}</div>
                </td>
            </a>
        </tbody>
    </table>
</div>
