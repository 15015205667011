import { Child } from 'app/models/api/child';
import { ApiService } from 'app/services/api/api.service';
import { ResponseParser } from 'app/services/api/parsers/response-parser';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ChildApiService {
    constructor(
        private apiService: ApiService,
        private responseParser: ResponseParser,
    ) {}

    saveChild(child: Child, authToken: string) {
        return this.apiService
            .post('/users/me/children', { body: child, headers: { Authorization: `Bearer ${authToken}` } })
            .pipe(map(response => this.responseParser.parseObject(response)));
    }
}
