import { Component, inject } from '@angular/core';
import { BaseUserProfileTabComponent } from 'app/components/user-profile/base-user-profile-tab.component';
import { Conversation } from 'app/models/api/conversation';
import { UserSubRouteType } from 'app/routing/route-type';
import { ConversationMeta, MessageApiService } from 'app/services/api/message.api.service';

@Component({
    selector: 'messages-tab',
    templateUrl: './messages-tab.component.html',
    styleUrls: ['./messages-tab.component.less'],
})
export class MessagesTabComponent extends BaseUserProfileTabComponent {
    conversations: Conversation[] = [];
    meta?: ConversationMeta;
    UserSubRouteType = UserSubRouteType;

    private messageApiService = inject(MessageApiService);

    onUserLoaded() {
        super.onUserLoaded();

        this.messageApiService.getConversations(this.user.id).subscribe(res => {
            this.conversations = res.data;
            this.meta = res.meta;
            this.initHeaderItems();
        });
    }

    protected initHeaderItems() {
        super.initHeaderItems();
        this.headerItems.push({
            text: `Response rate: ${
                this.meta?.responseRate?.receivedCount
                    ? 100 - Math.ceil((100 * this.meta.responseRate.unansweredCount) / this.meta.responseRate.receivedCount)
                    : '-'
            }%`,
        });
        this.headerItems.push({
            text: `Initial messages: ${this.meta?.initialMessageCount}`,
        });
        this.headerItems.push({
            text: `Total messages sent: ${this.meta?.sentMessageCount}`,
        });
        this.headerItems.push({
            text: `Total messages received: ${this.meta?.receivedMessageCount}`,
        });
    }
}
