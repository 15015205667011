import { ApiService } from 'app/services/api/api.service';
import { map } from 'rxjs/operators';
import { ResponseParser } from 'app/services/api/parsers/response-parser';
import { Injectable } from '@angular/core';
import { Conversation } from 'app/models/api/conversation';
import { Message } from 'app/models/api/message';

export interface ResponseRate {
    unansweredCount: number;
    receivedCount: number;
}
export interface ConversationMeta {
    initialMessageCount: number;
    receivedMessageCount: number;
    sentMessageCount: number;
    totalUnreadMessagesCount: number;
    responseRate?: ResponseRate;
}

@Injectable({
    providedIn: 'root',
})
export class MessageApiService {
    constructor(
        private apiService: ApiService,
        private responseParser: ResponseParser,
    ) {}

    getConversations(userId: string) {
        return this.apiService
            .get(`/gem/sitly-users/${userId}/conversations`)
            .pipe(map(response => this.responseParser.parseObject<Conversation[], ConversationMeta>(response)));
    }

    getChat(userId: string, chatPartnerId: string) {
        return this.apiService
            .get(`/gem/sitly-users/${userId}/conversations/${chatPartnerId}/messages`)
            .pipe(map(response => this.responseParser.parseObject<Message[]>(response)));
    }
}
