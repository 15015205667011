import { ServerResponseData } from 'app/models/api/response';
import { SensitivePhrase } from 'app/models/api/sensitive-phrase';
import { BaseParser } from 'app/services/api/parsers/base-parser';
import { ParserMap } from 'app/services/api/parsers/response-parser';

export class SensitivePhraseParser extends BaseParser {
    parse(data: ServerResponseData, _included: ServerResponseData[], _parsersMap: ParserMap) {
        const item = super.parseBase(SensitivePhrase, data);
        return item;
    }
}
