<div class="select-container" (clickOutside)="showOptions = false">
    <button type="button" class="btn-transparent btn-select btn-options" (click)="showOptions = true">
        <ng-template [ngIf]="!selected">{{ placeholder }}</ng-template>
        <ng-template [ngIf]="selected">{{ selected.label }}</ng-template>
    </button>
    <div class="select-options" [class.visible]="showOptions" [class.invisible]="!showOptions">
        <div
            *ngFor="let option of options"
            class="option"
            id="{{ option.id }}"
            (click)="onOptionSelected(option)"
            [class.invisible]="selected?.id === option.id"
        >
            {{ option.label }}
        </div>
    </div>
</div>
