import { Injectable } from '@angular/core';
import { ApiService } from 'app/services/api/api.service';
import { ResponseParser } from 'app/services/api/parsers/response-parser';
import { DurationUnit, Subscription } from 'app/models/api/subscription';
import { map } from 'rxjs/operators';
import { UserRoleId } from 'app/models/api/user';

interface CreationValues {
    duration: number;
    durationUnit: DurationUnit;
    pricePerUnit: number;
    webroleId: UserRoleId;
    maxAge?: number;
    showInOverview?: 0 | 1;
}

@Injectable({
    providedIn: 'root',
})
export class SubscriptionApiService {
    constructor(
        private apiService: ApiService,
        private responseParser: ResponseParser,
    ) {}

    getSubscriptions(filter?: { webroleId?: UserRoleId; showInOverview?: 0 | 1 }) {
        return this.apiService
            .get('/gem/subscriptions', filter ? { params: { filter } } : {})
            .pipe(map(response => this.responseParser.parseObject<Subscription[]>(response)));
    }

    deleteSubscription(subscriptionId: number) {
        return this.apiService.delete(`/gem/subscriptions/${subscriptionId}`);
    }

    createSubscription(values: CreationValues) {
        return this.apiService.post('/gem/subscriptions', { body: values });
    }

    createTestSubscription(
        subcriptionId: string,
        values: {
            duration: number;
            durationUnit: DurationUnit;
            pricePerUnit: number;
            abTestId: string;
        },
    ) {
        return this.apiService.post(`/gem/subscriptions/${subcriptionId}`, { body: values });
    }

    updateSubscription(subcriptionId: string, values: Partial<CreationValues>) {
        return this.apiService.patch(`/gem/subscriptions/${subcriptionId}`, { body: values });
    }
}
