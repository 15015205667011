import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[text-autoselect]',
})
export class TextAutoSelectDirective {
    @Input('autoselect-active') active = true;

    constructor(private element: ElementRef<HTMLImageElement>) {}

    @HostListener('click', ['$event'])
    onClick(event: Event) {
        if (!this.active) {
            return;
        }

        event.preventDefault();
        event.stopImmediatePropagation();
        this.selectAll();
    }

    private selectAll() {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(this.element.nativeElement);
        selection?.removeAllRanges();
        selection?.addRange(range);
    }
}
