import { Directive, ElementRef, Input } from '@angular/core';
import { User, Gender } from 'app/models/api/user';
import { ImgSizeUtil } from 'app/utils/img-size.utils';

@Directive({
    selector: '[avatar]',
})
export class AvatarDirective {
    @Input() size = 100;
    @Input() user?: User;
    @Input() url?: string;

    constructor(private element: ElementRef<HTMLImageElement>) {}

    ngOnChanges() {
        const url = this.user?.links?.avatar ?? this.url;
        if (url) {
            this.element.nativeElement.src = ImgSizeUtil.url(url, this.size, true);
        } else {
            this.setDefaultAvatar();
        }
    }

    private setDefaultAvatar() {
        const fileName = this.user?.isParent
            ? 'placholder-job'
            : this.user?.gender === Gender.male
              ? 'placeholder-sitter-male'
              : 'placeholder-sitter-female';
        const defaultAvatarUrl = `https://cdn.sitly.com/images/${fileName}.jpg`;
        this.element.nativeElement.src = ImgSizeUtil.url(defaultAvatarUrl, this.size);
    }
}
