<div class="filter-container" (clickOutside)="showOptions = false">
    <button type="button" class="btn-transparent btn-filter btn-options" (click)="showOptions = true">
        {{ placeholder }} <span class="filter-count" *ngIf="checkedOptionsNumber > 0">{{ checkedOptionsNumber }}</span>
    </button>
    <div class="filter-options" [class.visible]="showOptions" [class.invisible]="!showOptions">
        <div *ngFor="let option of options" class="option" id="{{ option.id }}">
            <input name="{{ option.id }}" id="{{ option.id }}" type="checkbox" [checked]="option.checked" (click)="onOptionClick(option)" />
            <label for="{{ option.id }}">{{ option.label }}</label>
        </div>
    </div>
</div>
