<div class="conversation-wrapper">
    <div class="action-container">
        <a class="btn-bordered" [routerLink]="['/', storageService.countryCode, 'users', model.chatPartner.id, 'profile']" target="_blank">
            Open partner profile
        </a>

        <button class="btn-bordered btn-close" (click)="navigationService.goUp()">Close conversation</button>
    </div>

    <ul class="conversation">
        <ng-template ngFor let-group [ngForOf]="model.parsedConversation" let-i="index">
            <li class="message-date" *ngIf="model.parsedConversation[i - 1]?.formattedDate !== group.formattedDate">
                <span>{{ group.formattedDate | titlecase }}</span>
            </li>

            <li class="message-group" [class.first]="i === 0 || model.parsedConversation[i - 1].formattedDate !== group.formattedDate">
                <img *ngIf="group.showLogoAvatar" class="message-avatar" image2x="logo-rounded" />
                <img
                    *ngIf="!group.showLogoAvatar && group.action"
                    class="message-avatar"
                    avatar
                    [url]="model.avatar(group.action)"
                    [size]="100"
                />

                <div class="message-container">
                    <div class="message-header">
                        <span class="sender-name">{{
                            model.groupHeader(group) ? model.groupHeader(group) : (model.senderFirstName(group) | titlecase)
                        }}</span>
                        <span class="send-time">{{ group.date | dfnsFormat: 'HH:mm' }}</span>
                    </div>

                    <ng-template [ngIf]="!group.isSafetyTips">
                        <div *ngFor="let message of group.messages">
                            <div class="message-content" [innerHTML]="message.text | nl2br"></div>

                            <a class="message-link" *ngIf="message.link" [href]="message.link">
                                <img image2x="external-link" />
                                {{ message.link }}
                            </a>
                        </div>
                    </ng-template>

                    <div *ngIf="group.isSafetyTips">
                        <div class="message-content" [innerHTML]="group.messages[0].text | nl2br"></div>
                    </div>
                </div>
            </li>
        </ng-template>
    </ul>
</div>
