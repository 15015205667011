import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { BaseComponent } from 'app/components/base.component';
import { User } from 'app/models/api/user';
import { UserApiService } from 'app/services/api/user.api.service';
import { UserService } from 'app/services/user.service';

export interface HeaderListItem {
    text: string;
    valueToCopy?: string;
}

@Component({
    selector: 'user-profile-tab-header',
    templateUrl: './user-profile-tab-header.component.html',
    styleUrls: ['./user-profile-tab-header.component.less'],
})
export class UserProfileHeaderComponent extends BaseComponent {
    @Input() user = new User();
    @Input() listItems?: HeaderListItem[];
    @Output() onUserUpdated = new EventEmitter<User>();

    @ViewChild('noteInput', { static: false }) noteInput?: ElementRef<HTMLTextAreaElement>;

    addingNote = false;
    editingNote = false;

    private userApiService = inject(UserApiService);
    private userService = inject(UserService);

    showAddNoteForm() {
        this.addingNote = true;
        setTimeout(() => this.noteInput?.nativeElement?.focus(), 0);
    }

    showEditNoteForm() {
        this.editingNote = true;
        setTimeout(() => {
            if (this.noteInput?.nativeElement) {
                this.noteInput.nativeElement.focus();
                this.noteInput.nativeElement.value = this.user.notes ?? '';
            }
        }, 0);
    }

    submitNote(value: string) {
        const trimmed = value.trim();
        if (trimmed) {
            const request = this.addingNote
                ? this.userApiService.postNote(this.user.id, trimmed)
                : this.userApiService.updateNote(this.user.id, trimmed);
            request.subscribe(res => {
                this.addingNote = false;
                this.editingNote = false;
                this.onUserUpdated.emit(res.data);
            });
        }
    }

    loginAsUser() {
        this.userService.loginAsUser(this.user.id);
    }
}
