import { GemUser } from 'app/models/api/gem-user';
import { BaseParser } from 'app/services/api/parsers/base-parser';
import { ServerResponseData } from 'app/models/api/response';
import { ParserMap } from 'app/services/api/parsers/response-parser';

export class GemUserParser extends BaseParser {
    parse(data: ServerResponseData, included: ServerResponseData[], parsersMap: ParserMap) {
        const item = super.parseBase(GemUser, data);
        item.countries = this.getRelationship('countries', data, included, parsersMap) ?? [];
        item.locales = this.getRelationship('locales', data, included, parsersMap) ?? [];
        return item;
    }
}
