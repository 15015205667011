import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface GemSelectCheckableOption {
    id: string;
    label: string;
    checked: boolean;
}

@Component({
    selector: 'gem-multi-select',
    templateUrl: './gem-multi-select.component.html',
    styleUrls: ['./gem-multi-select.component.less'],
})
export class GemMultiSelectComponent {
    @Input() options: GemSelectCheckableOption[] = [];
    @Input() placeholder?: string;

    @Output() changed = new EventEmitter<string[]>();

    showOptions = false;

    get checkedOptionsNumber() {
        return this.options.filter(option => option.checked).length;
    }

    onOptionClick(option: GemSelectCheckableOption) {
        option.checked = !option.checked;
        this.changed.emit(this.options.filter(item => item.checked).map(item => item.id));
    }
}
