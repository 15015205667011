import { MessageApiService } from 'app/services/api/message.api.service';
import { BaseUserProfileTabComponent } from 'app/components/user-profile/base-user-profile-tab.component';
import { Component, OnInit, ChangeDetectorRef, inject } from '@angular/core';
import { ChatModel } from 'app/components/user-profile/messages-tab/chat/chat.model';
import { Router } from '@angular/router';

@Component({
    selector: 'chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.less'],
})
export class ChatComponent extends BaseUserProfileTabComponent implements OnInit {
    model = new ChatModel();

    private router = inject(Router);
    private messageService = inject(MessageApiService);
    private cd = inject(ChangeDetectorRef);

    ngOnInit(): void {
        const chatPartnerId = this.router.url.split('/').pop();
        if (chatPartnerId) {
            this.userApiService.getUser(chatPartnerId).subscribe(res => {
                this.model.chatPartner = res.data;
                this.cd.markForCheck();
            });

            this.messageService.getChat(this.model.user.id, chatPartnerId).subscribe(response => {
                if (response) {
                    this.model.messages = [];
                    this.model.updateWithServerResponse(response);
                }
                this.cd.markForCheck();
            });
        }
    }

    onUserLoaded() {
        super.onUserLoaded();

        this.model.user = this.user;
        this.cd.markForCheck();
    }
}
