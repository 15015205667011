import { Directive, Input, ElementRef } from '@angular/core';

@Directive({
    selector: '[image2x]',
})
export class ImageDirective {
    private _extension = 'png';
    private _imageName?: string;

    @Input() set image2x(imageName: string) {
        this._imageName = imageName;
        this.element.nativeElement.src = `assets/images/${this._imageName}.${this._extension}`;
        this.element.nativeElement.srcset = `assets/images/${this._imageName}@2x.${this._extension} 2x`;
    }

    @Input() set extension(value: string) {
        this._extension = value;
        if (this._imageName) {
            this.image2x = this._imageName;
        }
    }

    constructor(private element: ElementRef<HTMLImageElement>) {}
}
