import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { BaseComponent } from 'app/components/base.component';
import { TranslationGroup } from 'app/models/api/translation-group';
import { TranslationApiService } from 'app/services/api/translation.api.service';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'groups',
    templateUrl: './groups.component.html',
    styleUrls: ['./groups.component.less'],
})
export class GroupsComponent extends BaseComponent implements OnInit {
    groups: TranslationGroup[] = [];
    loading = false;

    @ViewChild('groupName', { static: true }) private inputElement?: ElementRef<HTMLInputElement>;

    private translationService = inject(TranslationApiService);
    private cd = inject(ChangeDetectorRef);

    ngOnInit() {
        this.translationService.getGroups().subscribe(response => {
            this.groups = response.data;
            this.cd.markForCheck();
        });
    }

    saveGroup() {
        if (!this.inputElement?.nativeElement.value) {
            return;
        }

        this.loading = true;
        this.translationService
            .saveGroup(this.inputElement?.nativeElement.value)
            .pipe(
                finalize(() => {
                    this.loading = false;
                    this.cd.markForCheck();
                }),
            )
            .subscribe(response => {
                if (this.inputElement) {
                    this.inputElement.nativeElement.value = '';
                }
                this.groups.push(response.data);
            });
    }
}
