import { BaseApiModel } from 'app/models/api/response';

export enum SensitivePhraseType {
    moderate = 'moderate',
    severe = 'severe',
}

export class SensitivePhraseMeta {
    rowCount?: number;
    page?: number;
    pageCount?: number;
}

export class SensitivePhrase extends BaseApiModel {
    phrase?: string;
    type?: SensitivePhraseType;

    get isModerate() {
        return this.type === SensitivePhraseType.moderate;
    }

    get isSevere() {
        return this.type === SensitivePhraseType.severe;
    }
}
