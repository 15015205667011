<div class="page-container">
    <h2 class="title">Add a GEM user</h2>

    <form [formGroup]="addUserForm" (submit)="add($event)">
        <input type="text" formControlName="firstName" placeholder="First name" />
        <input type="text" formControlName="lastName" placeholder="Last name" />
        <input type="email" formControlName="email" placeholder="E-mail address" autocomplete="username" />
        <select formControlName="role" class="role-select">
            <option *ngFor="let option of allGemUserRoles" [value]="option">{{ option | lowercase | titlecase }}</option>
        </select>

        <div class="countries-container">
            <div class="country-title">Countries</div>

            <div class="country-container all">
                <input type="checkbox" [checked]="selectedAllCountries" (click)="selectAllCountries()" />
                <label>Select all</label>
            </div>
            <div class="country-container" *ngFor="let country of allCountries">
                <input type="checkbox" [(ngModel)]="selectedCountries[country]" [ngModelOptions]="{ standalone: true }" />
                <label>{{ country | uppercase }}</label>
            </div>
        </div>

        <span *ngIf="errorDescription" class="failed">{{ errorDescription }}</span>

        <div class="buttons-container">
            <button class="btn-bordered" [disabled]="submitting" (click)="navigationService.navigateTo(RouteType.settings)">Cancel</button>
            <button type="submit" class="btn-solid-blue btn-submit" [disabled]="submitting">Add GEM user</button>
        </div>
    </form>
</div>
