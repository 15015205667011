import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ViewPhotoDialogData {
    photoUrl: string;
}

@Component({
    selector: 'view-photo',
    templateUrl: './view-photo.component.html',
    styleUrls: ['./view-photo.component.less'],
})
export class ViewPhotoDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: ViewPhotoDialogData) {}
}
