import { BaseApiModel } from 'app/models/api/response';
import { UserRoleId } from 'app/models/api/user';

export enum DurationUnit {
    days = 'days',
    weeks = 'weeks',
    months = 'months',
    years = 'years',
}

export class Subscription extends BaseApiModel {
    duration = 0;
    durationUnit = DurationUnit.days;
    pricePerUnit = 0;
    webroleId = UserRoleId.parent;
    abTestId?: string;
    maxAge?: number;
    showInOverview = false;
    testVariant?: Subscription;
}
