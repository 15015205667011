export class BaseApiModel {
    id = '';
    meta?: unknown;
    links?: unknown;
}

export interface Relationship {
    type: string;
    id: string;
}

export interface ServerResponseData {
    type: string;
    id: string;
    attributes: { id: string; [selector: string]: unknown };
    meta?: unknown;
    links?: unknown;
    relationships?: Record<string, { data: Relationship | Relationship[] }>;
}
export interface ServerResponse {
    data: ServerResponseData | ServerResponseData[];
    included: ServerResponseData[];
    meta?: unknown;
    links?: unknown;
}

export interface ParsedResponse<T, M = unknown, L = unknown> {
    data: T;
    meta?: M;
    links?: L;
}
