import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
    selector: 'rating-star',
    templateUrl: './rating-star.component.html',
    styleUrls: ['./rating-star.component.less'],
})
export class RatingStarComponent {
    @Input() set fulness(value: number) {
        const fulness = !value || value < 0 || value > 100 ? 0 : value;
        if (this.foregroundStar) {
            this.foregroundStar.nativeElement.style.width = `${fulness}%`;
        }
    }

    @Input() set size(value: number) {
        const size: number = !value || value < 0 ? 0 : +value;
        if (this.container) {
            this.container.nativeElement.style.width = `${size + 2}px`; // parent has 1px horizontal margin
            this.container.nativeElement.style.height = `${size}px`;
        }

        if (this.foregroundStar) {
            this.foregroundStar.nativeElement.style.fontSize = `${size}px`;
        }

        if (this.backgroundStar) {
            this.backgroundStar.nativeElement.style.fontSize = `${size}px`;
        }
    }

    @Input() hasBorder = false;

    @Input('foregroundColor') set foregroundColor(value: string) {
        if (this.foregroundStar) {
            this.foregroundStar.nativeElement.style.color = value;
        }
    }
    @Input('backgroundColor') set backgroundColor(value: string) {
        if (this.backgroundStar) {
            this.backgroundStar.nativeElement.style.color = value;
        }
    }

    @ViewChild('foregroundStar', { static: true }) private foregroundStar?: ElementRef<HTMLSpanElement>;
    @ViewChild('backgroundStar', { static: true }) private backgroundStar?: ElementRef<HTMLSpanElement>;
    @ViewChild('container', { static: true }) private container?: ElementRef<HTMLDivElement>;
}
