<div class="container">
    <div class="input-container">
        <input #groupName type="text" />
        <button class="btn-solid-green" (click)="saveGroup()" [disabled]="loading">Save</button>
    </div>

    <div class="groups-list">
        <div *ngFor="let group of groups" class="group-item">{{ group.groupId }} - {{ group.name }}</div>
    </div>
</div>
