<div class="profile-tab">
    <user-profile-tab-header [user]="user" [listItems]="headerItems" (onUserUpdated)="onUserUpdated.emit($event)"></user-profile-tab-header>

    <form class="dates-section">
        <div class="section-row">
            <label for="premium">Premium expiry date</label>
            <input type="date" name="premium" [value]="premiumExpiryDate" (change)="save($event)" />
        </div>

        <div class="section-row">
            <label for="gracePeriod">Grace period expiry date</label>
            <input type="date" name="gracePeriod" [value]="gracePeriod" (change)="save($event)" />
        </div>
    </form>

    <div class="cancellation-section">
        <button
            *ngIf="user.canCancelPremium"
            class="btn-bordered btn-cancel-premium"
            (click)="saveUserUpdate({ subscriptionCancelled: true })"
        >
            Cancel premium subscription
        </button>
        <div *ngIf="user.subscriptionCancellationDate" class="cancellation-description">
            Subscription cancelled on <span>{{ user.subscriptionCancellationDate | std | dfnsFormat: 'dd-MM-yyyy' }}</span>
        </div>
    </div>

    <div class="profile-section">
        <div class="section-header">Promotions & offers</div>

        <div class="checkbox-row">
            <input type="checkbox" name="usedPremiumExtension" id="usedPremiumExtension" [checked]="user.premiumExtensionUsed" disabled />
            <label for="usedPremiumExtension">Used free premium extension of 2 months</label>
        </div>

        <div class="checkbox-row">
            <input type="checkbox" name="usedDiscount" id="usedDiscount" [checked]="user.discountPercentage > 0" disabled />
            <label for="usedDiscount">
                Used winback offer<span *ngIf="user.discountPercentage > 0">: {{ user.discountPercentage }}% discount</span>
            </label>
        </div>
    </div>

    <div class="profile-section">
        <div class="section-header">Orders</div>

        <div class="gem-table">
            <div class="gem-table-header">
                <div class="col col-type">Type</div>
                <div class="col col-amount">Amount</div>
                <div class="col col-method">Method</div>
                <div class="col col-date">Date</div>
                <div class="col col-platform">Platform & ID</div>
                <div class="col col-refund">Status</div>
            </div>

            <div *ngFor="let payment of user.payments" class="row">
                <div class="col col-type">
                    {{ payment.orderType | titlecase }}
                </div>
                <div class="col col-amount">
                    {{ countrySettings?.moneyFormat?.replace('[amount]', payment.amount.toString()) ?? '-' }}
                </div>
                <div class="col col-method">
                    {{ payment.paymentMethod ?? '-' }}
                </div>
                <div class="col col-date">
                    {{ payment.created | std | dfnsFormat: "dd-MM-''yy" }}
                </div>
                <div class="col col-platform">
                    {{ payment.psp ?? 'adyen' | titlecase }}<br />
                    <ng-template [ngIf]="payment.psp !== 'google'">{{ payment.id }}</ng-template>
                    <ng-template [ngIf]="payment.psp === 'google'">{{ payment.pspReference }}</ng-template>
                </div>
                <div class="col col-refund">
                    {{ payment.refunded ? 'Refunded\n' + (payment.chargebackTime | std | dfnsFormat: "dd-MM-''yy") : '-' }}
                </div>
            </div>
        </div>
    </div>
</div>
