import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface AlertDialogDate {
    title: string;
    confirmButtonTitle?: string;
    description?: string;
    dismissButtonTitle?: string;
}

@Component({
    selector: 'alert-dialog-content',
    templateUrl: './alert-dialog.component.html',
    styleUrls: ['./alert-dialog.component.less'],
})
export class AlertDialogContentComponent {
    static genericConfig = {
        position: { top: '150px' },
        maxWidth: '500px',
        minWidth: '300px',
        panelClass: 'alert-dialog',
    };

    @Output() onConfirm = new EventEmitter();

    constructor(@Inject(MAT_DIALOG_DATA) public data: AlertDialogDate) {}

    static config(data: AlertDialogDate) {
        return {
            ...AlertDialogContentComponent.genericConfig,
            data,
        };
    }
}
