<div class="profile-tab">
    Total logs count limited to 500. Current user logs count: {{ totalLogsCount }}
    <div class="gem-table">
        <div class="gem-table-header">
            <div class="col col-date">Date</div>
            <div class="col col-label">Label</div>
            <div class="col col-message">Message</div>
            <div class="col col-extra"></div>
        </div>

        <div *ngFor="let log of logs">
            <div class="row" [class.hide-border]="log.expanded">
                <div class="col col-date">
                    {{ log.log.created_at | std | dfnsFormat: 'yyyy-MM-dd\n HH:mm:ss' }}
                </div>
                <div class="col col-label">
                    {{ log.log.label }}
                </div>
                <div class="col col-message">
                    {{ log.log.message }}
                </div>
                <div class="col col-extra">
                    <button class="btn-link" (click)="log.expanded = !log.expanded">
                        {{ log.expanded ? 'Hide detail' : 'Show details' }}
                    </button>
                </div>
            </div>

            <div *ngIf="log.expanded" class="row row-details">
                <pre>{{ log.log | json }}</pre>
            </div>
        </div>
    </div>
</div>
