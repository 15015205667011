import { BaseApiModel } from 'app/models/api/response';
import { Country } from 'app/models/api/country';
import { Locale } from 'app/models/api/locale';

export enum GemUserRole {
    admin = 'ADMIN',
    support = 'SUPPORT',
    tester = 'TESTER',
    translator = 'TRANSLATOR',
    customerResearcher = 'CUSTOMER_RESEARCHER',
}
export const allGemUserRoles = Object.values(GemUserRole);

export class GemUser extends BaseApiModel {
    email = '';
    firstName = '';
    lastName = '';
    role = GemUserRole.tester;

    countries: Country[] = [];
    locales: Locale[] = [];

    get isAdmin() {
        return this.role === GemUserRole.admin;
    }
}
