import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import { AppComponent } from 'app/components/app/app.component';
import { LoginComponent } from 'app/components/login/login.component';
import { NotFoundComponent } from 'app/components/not-found/not-found.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SideMenuComponent } from 'app/components/side-menu/side-menu.component';
import { SitlyUsersComponent } from 'app/components/sitly-users/sitly-users.component';
import { ImageDirective } from 'app/directives/image.directive';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AlertDialogContentComponent } from 'app/components/common/alert-dialog/alert-dialog.component';
import { DateFnsModule } from 'ngx-date-fns';
import { StringToDatePipe } from 'app/pipes/string-to-date.pipe';
import { Nl2br } from 'app/pipes/nl2br.pipe';
import { ClickOutsideDirective } from 'app/directives/click-outside.directive';
import { ScreeningComponent } from 'app/components/screening/screening.component';
import { UserProfileComponent } from 'app/components/user-profile/user-profile.component';
import { ProfileTabComponent } from 'app/components/user-profile/profile-tab/profile-tab.component';
import { SubscriptionTabComponent } from 'app/components/user-profile/subscription-tab/subscription-tab.component';
import { MessagesTabComponent } from 'app/components/user-profile/messages-tab/messages-tab.component';
import { ReviewsTabComponent } from 'app/components/user-profile/reviews-tab/reviews-tab.component';
import { UserProfileHeaderComponent } from 'app/components/user-profile/user-profile-header/user-profile-tab-header.component';
import { RatingBarComponent } from 'app/components/common/rating-bar/rating-bar.component';
import { RatingStarComponent } from 'app/components/common/rating-bar/rating-star/rating-star.component';
import { ViewPhotoDialogComponent } from 'app/components/common/alert-dialog/view-photo/view-photo.component';
import localeNl from '@angular/common/locales/nl';
import { registerLocaleData } from '@angular/common';
import { ApiInterceptor } from 'app/services/api/api.service';
import { AvatarDirective } from 'app/directives/avatar.directive';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ChatComponent } from 'app/components/user-profile/messages-tab/chat/chat.component';
import { ScreeningGeneralComponent } from 'app/components/screening/general/general.component';
import { ScreeningPersonalDataComponent } from 'app/components/screening/personal-data/personal-data.component';
import { GemSelectComponent } from 'app/components/common/gem-select/gem-select.component';
import { RoundCheckboxComponent } from 'app/components/common/round-checkbox/round-checkbox.component';
import { SettingsComponent } from 'app/components/settings/settings.component';
import { HighlightPipe } from 'app/pipes/highlight.pipe';
import { ScreeningSensitivePhrasesComponent } from 'app/components/screening/sensitive-phrases/sensitive-phrases.component';
import { ScreeningExclusionsComponent } from 'app/components/screening/exclusions/exclusions.component';
import { AddGemUserComponent } from 'app/components/settings/add-gem-user/add-gem-user.component';
import { GemMultiSelectComponent } from 'app/components/common/gem-multi-select/gem-multi-select.component';
import { RouteReuseStrategy } from '@angular/router';
import { CacheRouteReuseStrategy } from 'app/routing/route-reuse-strategy';
import { LogsTabComponent } from 'app/components/user-profile/logs-tab/logs-tab.component';
import { TextAutoSelectDirective } from 'app/directives/text-autoselect.directive';
import { MatchesTabComponent } from 'app/components/user-profile/matches-tab/matches-tab.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CamelCaseToSpaceSeparatedPipe } from 'app/pipes/camel-case-to-space-separated.pipe';
import { TranslationsComponent } from 'app/components/translations/translations.component';
import { TranslateComponent } from 'app/components/translations/translate/translate.component';
import { AddTranslationComponent } from 'app/components/translations/add/add.component';
import { PublishComponent } from 'app/components/translations/publish/publish.component';
import { GroupsComponent } from 'app/components/translations/groups/groups.component';
import { TranslationEnvironmentValuePipe } from 'app/pipes/translation-environment-value.pipe';
import { SubscriptionsComponent } from 'app/components/subscriptions/subscriptions.component';
import { SubscriptionFormComponent } from 'app/components/subscriptions/subscription-form/subscription-form.component';
import { FeedbacksComponent } from 'app/components/feedbacks/feedbacks.component';
import { EmailsTabComponent } from 'app/components/user-profile/emails-tab/emails-tab.component';

registerLocaleData(localeNl);

declare global {
    interface Array<T> {
        aggregatedDescription(lastSeparator?: string): T;
    }
}

Array.prototype.aggregatedDescription = function (lastSeparator?: string) {
    if (this.length === 0) {
        return '';
    }
    return this.reduce((previous, current, index, array) => {
        if (index === array.length - 1) {
            return `${previous}${lastSeparator ?? ' & '}${current}`;
        } else {
            return `${previous}, ${current}`;
        }
    }) as string;
};

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        NotFoundComponent,
        SideMenuComponent,
        SitlyUsersComponent,
        ScreeningComponent,
        UserProfileComponent,
        AlertDialogContentComponent,
        ProfileTabComponent,
        SubscriptionTabComponent,
        MessagesTabComponent,
        ReviewsTabComponent,
        StringToDatePipe,
        Nl2br,
        ClickOutsideDirective,
        ImageDirective,
        UserProfileHeaderComponent,
        RatingBarComponent,
        RatingStarComponent,
        ViewPhotoDialogComponent,
        AvatarDirective,
        TextAutoSelectDirective,
        ChatComponent,
        ScreeningGeneralComponent,
        ScreeningPersonalDataComponent,
        GemSelectComponent,
        RoundCheckboxComponent,
        SettingsComponent,
        HighlightPipe,
        TranslationEnvironmentValuePipe,
        CamelCaseToSpaceSeparatedPipe,
        ScreeningSensitivePhrasesComponent,
        ScreeningExclusionsComponent,
        AddGemUserComponent,
        GemMultiSelectComponent,
        LogsTabComponent,
        MatchesTabComponent,
        TranslationsComponent,
        TranslateComponent,
        AddTranslationComponent,
        PublishComponent,
        GroupsComponent,
        SubscriptionsComponent,
        SubscriptionFormComponent,
        FeedbacksComponent,
        EmailsTabComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        MatDialogModule,
        MatPaginatorModule,
        DateFnsModule.forRoot(),
        ClipboardModule,
        InfiniteScrollModule,
    ],
    providers: [
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
        { provide: RouteReuseStrategy, useClass: CacheRouteReuseStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
})
export class AppModule {}
