<div class="page-container">
    <div class="users" *ngIf="users.length > 0">
        <div class="selector">
            <round-checkbox
                #selectAll
                id="all"
                label="All"
                [checked]="isAllSelected"
                (changed)="toggleAllSelection($event)"
            ></round-checkbox>
        </div>
        <div class="users-header">
            <div class="col-select"></div>
            <div class="col-avatar">Avatar</div>
            <div class="col-user">User</div>
            <div class="col-flag">Flag</div>
            <div class="col-content">Content</div>
        </div>
        <div>
            <div *ngFor="let item of users" class="row-user">
                <div class="col-select">
                    <round-checkbox
                        id="item.user.id"
                        [checked]="item.selected"
                        (changed)="toggleUserSelection($event, item)"
                    ></round-checkbox>
                </div>
                <a [routerLink]="['/', storageService.countryCode, 'users', item.user.id, 'profile']" target="_blank">
                    <div class="col-group-user" [class.selected]="item.selected">
                        <div class="col-avatar">
                            <div class="photo">
                                <img avatar [user]="item.user" [size]="100" />
                            </div>
                        </div>
                        <div class="col-user">
                            <span text-autoselect>
                                <div>{{ item.user.firstName }} {{ item.user.lastName }}</div>
                                <div class="user-status">
                                    {{ item.user.role | titlecase }} - {{ item.user.isPremium ? 'Premium' : 'Basic' }} member
                                </div>
                                <div class="user-status" *ngIf="(item.user.warnings?.length ?? 0) > 1">
                                    {{ item.user.warnings.length }} warnings
                                </div>
                                <div class="user-status" *ngIf="item.user.warnings?.length === 1">1 warning</div>
                            </span>
                        </div>
                        <div class="col-flag">
                            <div>Masked personal data</div>
                            <span text-autoselect>
                                <div *ngFor="let replacement of item.user.meta?.aboutReplacements" class="masked-data">
                                    {{ replacement }}
                                </div>
                            </span>
                        </div>
                        <div class="col-content">
                            <textarea
                                #aboutInput
                                (blur)="onAboutBlur(item, aboutInput.value)"
                                (click)="onAboutClick($event)"
                                value="{{ item.user.meta?.replacedAbout }}"
                            >
                            </textarea>
                            <div class="content-btn-container">
                                <button class="btn-solid-grey-blue btn-ignore" (click)="ignoreUser($event, item)">Ignore</button>
                                <button
                                    *ngIf="!item.user.isParent"
                                    class="btn-solid-yellow btn-underage"
                                    (click)="deleteUnderageUser($event, item)"
                                >
                                    <img image2x="underage" />
                                    Underage
                                </button>
                                <button class="btn-solid-pink btn-save" (click)="saveUserData($event, item)">
                                    Save text &amp; send warning
                                </button>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>

    <div class="bulk-action-container" *ngIf="hasSelectedUsers">
        <div class="action-text">
            <ng-template [ngIf]="selectedUsersNumber === 1">1 selected user:</ng-template>
            <ng-template [ngIf]="selectedUsersNumber > 1">{{ selectedUsersNumber }} selected users: </ng-template>
        </div>
        <button class="btn btn-solid-green btn-ignore" (click)="ignoreSelectedUsers()">Ignore</button>
        <button *ngIf="hasSelectedSitters" class="btn btn-solid-yellow btn-underage" (click)="deleteUnderageSelectedUsers()">
            <img image2x="underage" />
            Underage
        </button>
    </div>

    <div #snackbar class="snackbar" [class.visible]="snackBarVisible"><img image2x="approve" /> {{ snackBarMessage }}</div>
</div>
