import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DurationUnit, Subscription } from 'app/models/api/subscription';
import { UserRole, UserRoleId } from 'app/models/api/user';
import { SubscriptionApiService } from 'app/services/api/subscription.api.service';
import { ApiError } from 'app/types';

@Component({
    selector: 'subscription-form',
    templateUrl: './subscription-form.component.html',
    styleUrls: ['./subscription-form.component.less'],
})
export class SubscriptionFormComponent implements OnInit {
    @Output() onConfirm = new EventEmitter();

    subscriptionForm = new FormGroup(
        {
            duration: new FormControl(1, {
                validators: [Validators.required],
                nonNullable: true,
            }),
            durationUnit: new FormControl(DurationUnit.months, {
                validators: [Validators.required],
                nonNullable: true,
            }),
            pricePerUnit: new FormControl<number>(0.01, {
                validators: [Validators.required, Validators.min(0.01)],
                nonNullable: true,
            }),
            maxAge: new FormControl<number | undefined>(undefined, {
                validators: [Validators.max(127)],
            }),
            userRole: new FormControl('', {
                validators: this.data.type !== 'create-test-variant' ? [Validators.required] : [],
                nonNullable: this.data.type !== 'create-test-variant',
            }),
            abTestId: new FormControl('', {
                validators: this.data.type === 'create-test-variant' ? [Validators.required] : [],
                nonNullable: this.data.type === 'create-test-variant',
            }),
            showInOverview: new FormControl(false, {
                validators: [Validators.required],
            }),
        },
        { updateOn: 'submit' },
    );
    allDurationUnits = Object.values(DurationUnit);
    allUserRoles = Object.values(UserRole);
    submitting = false;
    errorDescription?: string;
    get title() {
        switch (this.data.type) {
            case 'create':
                return 'Create subcription';
            case 'create-test-variant':
                return 'Create subcription test variant';
            case 'edit':
                return 'Edit subcription';
        }
    }

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data:
            | {
                  type: 'create';
              }
            | {
                  type: 'create-test-variant';
                  subscription: Subscription;
              }
            | {
                  type: 'edit';
                  subscription: Subscription;
              },
        private subscriptionService: SubscriptionApiService,
        private dialogRef: MatDialogRef<SubscriptionFormComponent>,
    ) {}

    ngOnInit(): void {
        if (this.data.type === 'create-test-variant' || this.data.type === 'edit') {
            const subscription = this.data.subscription;
            this.subscriptionForm.controls.duration.setValue(subscription.duration);
            this.subscriptionForm.controls.durationUnit.setValue(subscription.durationUnit);
            this.subscriptionForm.controls.pricePerUnit.setValue(subscription.pricePerUnit);
            this.subscriptionForm.controls.maxAge.setValue(subscription.maxAge);
            this.subscriptionForm.controls.userRole.setValue(this.allUserRoles[subscription.webroleId - 1]);
            this.subscriptionForm.controls.showInOverview.setValue(subscription.showInOverview);
        }
    }

    submit(event: Event) {
        event.preventDefault();
        if (!this.subscriptionForm.valid) {
            this.errorDescription = Object.entries(this.subscriptionForm.controls).reduce((prev, current) => {
                if (current[1].errors) {
                    if (prev.length > 0) {
                        console.log('---', prev);
                        prev += ',\n';
                    }
                    prev += `${current[0]
                        .split(/(?=[A-Z])/)
                        .join(' ')
                        .toLowerCase()} ${current[1].errors.required ? 'is required' : 'has invalid value'}`;
                }
                return prev;
            }, '');
            return;
        }

        this.errorDescription = undefined;
        this.submitting = true;

        const onSuccess = () => {
            this.submitting = false;
            this.onConfirm.emit();
            this.dialogRef.close();
        };
        const onError = (err: ApiError) => {
            this.errorDescription = err.error?.errors?.[0]?.title;
            this.submitting = false;
        };

        const value = this.subscriptionForm.getRawValue();
        switch (this.data.type) {
            case 'create': {
                return this.subscriptionService
                    .createSubscription({
                        duration: value.duration,
                        durationUnit: value.durationUnit,
                        pricePerUnit: value.pricePerUnit,
                        webroleId: UserRoleId[value.userRole as UserRole],
                        maxAge: value.maxAge ?? undefined,
                        showInOverview: value.showInOverview ? 1 : 0,
                    })
                    .subscribe(onSuccess, onError);
            }
            case 'create-test-variant': {
                return this.subscriptionService
                    .createTestSubscription(this.data.subscription.id, {
                        duration: value.duration,
                        durationUnit: value.durationUnit,
                        pricePerUnit: value.pricePerUnit,
                        abTestId: value.abTestId ?? '',
                    })
                    .subscribe(onSuccess, onError);
            }
            case 'edit': {
                return this.subscriptionService
                    .updateSubscription(this.data.subscription.id, {
                        duration: value.duration !== this.data.subscription.duration ? value.duration : undefined,
                        durationUnit: value.durationUnit !== this.data.subscription.durationUnit ? value.durationUnit : undefined,
                        pricePerUnit: value.pricePerUnit !== this.data.subscription.pricePerUnit ? value.pricePerUnit : undefined,
                        webroleId: UserRoleId[value.userRole as UserRole],
                        maxAge: value.maxAge ?? undefined,
                        showInOverview: value.showInOverview ? 1 : 0,
                    })
                    .subscribe(onSuccess, onError);
            }
        }
    }
}
