import { BaseParser } from 'app/services/api/parsers/base-parser';
import { ServerResponseData } from 'app/models/api/response';
import { Feedback } from 'app/models/api/feedback';

export class FeedbackParser extends BaseParser {
    parse(data: ServerResponseData) {
        const item = super.parseBase(Feedback, data);
        return item;
    }
}
