import { Component, OnInit, inject } from '@angular/core';
import { ScreeningPersonalDataApiService } from 'app/services/api/personal-data.api.service';
import { BaseComponent } from 'app/components/base.component';
import { UserWarningApiService, WarnedUserAction } from 'app/services/api/user.warning.api.service';
import { SelectableUser } from 'app/components/screening/general/general.component.models';

@Component({
    selector: 'screening-personal-data',
    templateUrl: './personal-data.component.html',
    styleUrls: ['./personal-data.component.less'],
})
export class ScreeningPersonalDataComponent extends BaseComponent implements OnInit {
    snackBarMessage?: string;
    snackBarVisible = false;
    users: SelectableUser[] = [];

    get selectedUsersNumber() {
        return this.users.filter(item => item.selected).length;
    }
    get hasSelectedUsers() {
        return this.selectedUsersNumber > 0;
    }
    get isAllSelected() {
        return !this.users.find(item => !item.selected);
    }
    get hasSelectedSitters() {
        return this.users.filter(item => item.selected).some(item => !item.user.isParent);
    }
    private get selectedIds() {
        return this.users.filter(item => item.selected).map(item => item.user.id);
    }

    private personalDataApiService = inject(ScreeningPersonalDataApiService);
    private userWarningApiService = inject(UserWarningApiService);

    ngOnInit() {
        this.refreshPage();
    }

    saveUserData(event: Event, user: SelectableUser) {
        event.stopPropagation();
        event.preventDefault();
        this.bulkSaveUserData([user]);
    }

    saveSelectedUserData() {
        this.bulkSaveUserData(this.users.filter(item => item.user));
    }

    ignoreSelectedUsers() {
        this.bulkIgnoreUsers(this.selectedIds);
    }

    ignoreUser(event: Event, user: SelectableUser) {
        event.stopPropagation();
        event.preventDefault();
        this.bulkIgnoreUsers([user.user.id]);
    }

    toggleUserSelection(checked: boolean, user: SelectableUser) {
        user.selected = checked;
    }

    toggleAllSelection(checked: boolean) {
        this.users.forEach(item => (item.selected = checked));
    }

    onAboutBlur(user: SelectableUser, value: string) {
        if (user.user.meta) {
            user.user.meta.replacedAbout = value;
        }
    }

    onAboutClick(event: Event) {
        event.stopPropagation();
        event.preventDefault();
    }

    deleteUnderageUser(event: Event, user: SelectableUser) {
        event.stopPropagation();
        event.preventDefault();

        this.bulkDeleteUnderageUsers([user.user.id]);
    }

    deleteUnderageSelectedUsers() {
        this.bulkDeleteUnderageUsers(this.users.filter(item => item.selected && !item.user.isParent).map(item => item.user.id));
    }

    private bulkDeleteUnderageUsers(userIds: string[]) {
        this.showAlert({
            title: `Are you sure you want to delete ${userIds.length === 1 ? 'this account' : 'these accounts'}?`,
            confirmButtonTitle: 'Yes, delete',
            dismissButtonTitle: 'Cancel',
        }).componentInstance.onConfirm.subscribe(() => {
            this.userWarningApiService.postWarnedUsersAction(WarnedUserAction.deleteUnderaged, userIds).subscribe(() => {
                const count = userIds.length;
                this.showSnackBar(count > 1 ? `${count} users deleted and warning messages sent` : 'User deleted and warning message sent');
                this.refreshPage();
            });
        });
    }

    private showSnackBar(message: string) {
        this.snackBarVisible = true;
        this.snackBarMessage = message;

        setTimeout(() => {
            this.snackBarVisible = false;
            this.snackBarMessage = undefined;
        }, 3000);
    }

    private bulkIgnoreUsers(userIds: string[]) {
        this.personalDataApiService.ignoreUsers(userIds).subscribe(() => {
            this.refreshPage();

            this.showSnackBar(`${userIds.length === 1 ? '1 user' : `${userIds.length} users`} removed from personal data list`);
        });
    }

    private bulkSaveUserData(users: SelectableUser[]) {
        const ids = users.map(item => item.user.id);
        const changes = users.map(item => item.user.meta?.replacedAbout ?? '');
        this.personalDataApiService.saveUserData(ids, changes).subscribe(() => {
            this.refreshPage();

            this.showSnackBar(`${users.length === 1 ? '1 user' : `${users.length} users`} saved and warned`);
        });
    }

    private refreshPage() {
        this.personalDataApiService
            .getPersonalDataUsers()
            .subscribe(response => (this.users = response.data.map(user => new SelectableUser(user))));
    }
}
