import { BaseParser } from 'app/services/api/parsers/base-parser';
import { ServerResponseData } from 'app/models/api/response';
import { ParserMap } from 'app/services/api/parsers/response-parser';
import { Subscription } from 'app/models/api/subscription';

export class SubscriptionParser extends BaseParser {
    parse(data: ServerResponseData, included: ServerResponseData[], parsersMap: ParserMap) {
        const item = super.parseBase(Subscription, data);
        item.testVariant = this.getRelationship('testVariant', data, included, parsersMap);
        return item;
    }
}
