import { Injectable } from '@angular/core';
import { SensitivePhrase, SensitivePhraseMeta, SensitivePhraseType } from 'app/models/api/sensitive-phrase';
import { ApiService, ParamsMapValue } from 'app/services/api/api.service';
import { map } from 'rxjs/operators';
import { ResponseParser } from 'app/services/api/parsers/response-parser';

export interface SensitivePhraseFilter {
    phrase?: string;
    types?: string[];
}

@Injectable({
    providedIn: 'root',
})
export class SensitivePhraseApiService {
    constructor(
        private apiService: ApiService,
        private responseParser: ResponseParser,
    ) {}

    getPhrases(pageNumber = 1, pageSize = 40, filter?: SensitivePhraseFilter) {
        return this.apiService
            .get('/gem/sensitive-phrases', {
                params: {
                    page: { size: pageSize, number: pageNumber },
                    filter: filter as ParamsMapValue,
                },
            })
            .pipe(map(response => this.responseParser.parseObject<SensitivePhrase[], SensitivePhraseMeta>(response)));
    }

    createPhrase(phrase: string, type: SensitivePhraseType) {
        return this.apiService
            .post('/gem/sensitive-phrases', {
                body: {
                    action: 'create',
                    payload: {
                        phrase,
                        type,
                    },
                },
            })
            .pipe(map(response => this.responseParser.parseObject<SensitivePhrase>(response)));
    }

    deletePhrases(ids: string[]) {
        return this.apiService.post('/gem/sensitive-phrases', {
            body: {
                action: 'delete',
                payload: {
                    ids: ids.map(item => parseInt(item, 0)),
                },
            },
        });
    }

    updatePhrase(phraseId: string, phrase?: string, type?: SensitivePhraseType) {
        return this.apiService
            .patch(`/gem/sensitive-phrases/${phraseId}`, {
                body: {
                    phrase,
                    type,
                },
            })
            .pipe(map(response => this.responseParser.parseObject<SensitivePhrase>(response)));
    }
}
