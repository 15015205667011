import { ServerResponseData } from 'app/models/api/response';
import { TranslationGroup } from 'app/models/api/translation-group';
import { BaseParser } from 'app/services/api/parsers/base-parser';
import { ParserMap } from 'app/services/api/parsers/response-parser';

export class TranslationGroupParser extends BaseParser {
    parse(data: ServerResponseData, _included: ServerResponseData[], _parsersMap: ParserMap) {
        const item = super.parseBase(TranslationGroup, data);
        item.groupId = parseInt(data.id);
        return item;
    }
}
