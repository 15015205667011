<button class="back-link" (click)="navigationService.navigateTo(RouteType.users)">&lt; Back to overview</button>

<div class="profile-summary">
    <div class="name">{{ user.firstName }} {{ user.lastName }}</div>

    <ul *ngIf="listItems?.length ?? 0 > 0">
        <li *ngFor="let item of listItems">
            <span innerHTML="{{ item.text }}"></span>
            <button *ngIf="item.valueToCopy" class="btn-copy" [cdkCopyToClipboard]="item.valueToCopy">
                <img image2x="copy" />
            </button>
        </li>
    </ul>

    <button class="btn-note" *ngIf="!addingNote && !editingNote" (click)="showAddNoteForm()">
        <img image2x="add-note" />
    </button>

    <div class="notes-container" *ngIf="!addingNote && !editingNote && user?.notes">
        <div class="notes-text">{{ user.notes }}</div>
        <button class="btn-edit" (click)="showEditNoteForm()">
            <img image2x="edit" />
        </button>
    </div>

    <div class="add-note-form" *ngIf="addingNote || editingNote">
        <textarea #noteInput></textarea>
        <button class="btn-post-note" (click)="submitNote(noteInput.value)">Add note</button>
    </div>

    <button class="btn-link btn-login" (click)="loginAsUser()">Login as user</button>
</div>
