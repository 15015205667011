<div class="profile-tab">
    <user-profile-tab-header [user]="user" [listItems]="headerItems" (onUserUpdated)="onUserUpdated.emit($event)"></user-profile-tab-header>

    <div class="gem-table">
        <div class="gem-table-header">
            <div class="col col-partner-photo">User</div>
            <div class="col col-partner-name">Conversation</div>
            <div class="col col-last-message">Last message</div>
        </div>

        <div *ngFor="let conversation of conversations" class="row" [routerLink]="UserSubRouteType.messages + '/' + conversation.id">
            <div class="col col-partner-photo">
                <img avatar [user]="conversation?.chatPartner" [size]="100" />
            </div>

            <div class="col col-partner-name">
                <div class="partner-name">{{ conversation.chatPartner?.firstName }} {{ conversation.chatPartner?.lastName }}</div>
                <div>{{ conversation.totalMessagesCount }} {{ (conversation.totalMessagesCount ?? 0) > 1 ? 'messages' : 'message' }}</div>
            </div>

            <div class="col col-last-message">
                <div class="last-message-date">{{ conversation.lastMessage?.date | dfnsFormat: 'dd-MM-yyyy' }}:</div>
                <div>
                    {{ conversation.lastMessage?.text }}
                </div>
            </div>
        </div>
    </div>
</div>
