<user-profile-tab-header [user]="user" [listItems]="headerItems" (onUserUpdated)="onUserUpdated.emit($event)"></user-profile-tab-header>

<div class="reviews-table">
    <div class="reviews-header">
        <div class="col-rating">Rating</div>
        <div class="col-text">Review text</div>
    </div>
    <div *ngFor="let review of user?.recommendations" class="review-item">
        <div class="col-rating">
            <rating-bar [rating]="review.score" color="#ffa300" [starSize]="18"></rating-bar>
        </div>
        <div class="col-text">
            <div class="author-name">{{ review.authorName }}</div>
            <div>{{ review.description }}</div>
        </div>

        <button class="btn-remove" (click)="removeReview(review.id)">
            <img image2x="bin" />
        </button>
    </div>
</div>
