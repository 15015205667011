<h2 class="title">{{ title }}</h2>
<form [formGroup]="subscriptionForm" (submit)="submit($event)">
    <label>
        Duration:
        <input type="number" formControlName="duration" />
    </label>

    <label>
        Duration unit:
        <select formControlName="durationUnit">
            <option *ngFor="let option of allDurationUnits" [value]="option">{{ option }}</option>
        </select>
    </label>

    <label>
        Price per unit:
        <input type="number" formControlName="pricePerUnit" />
    </label>

    <label *ngIf="data.type !== 'create-test-variant'">
        Max age:
        <input type="number" formControlName="maxAge" />
    </label>

    <label *ngIf="data.type === 'create-test-variant'">
        Test id:
        <input *ngIf="data.type === 'create-test-variant'" type="text" formControlName="abTestId" />
    </label>

    <label *ngIf="data.type !== 'create-test-variant'">
        User role:
        <select type="number" formControlName="userRole">
            <option *ngFor="let option of allUserRoles" [value]="option">{{ option }}</option>
        </select>
    </label>

    <label *ngIf="data.type !== 'create-test-variant'">
        Show in overview:
        <input type="checkbox" formControlName="showInOverview" />
    </label>

    <span *ngIf="errorDescription" class="failed">{{ errorDescription }}</span>

    <div class="buttons-container">
        <button mat-dialog-close class="btn-bordered">{{ 'Cancel' }}</button>
        <button type="submit" class="btn-solid-blue btn-confirm" [disabled]="submitting">
            {{ data.type === 'edit' ? 'Save' : 'Create' }}
        </button>
    </div>
</form>
