import { BaseComponent } from 'app/components/base.component';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.less'],
})
export class LoginComponent extends BaseComponent implements OnInit {
    loggingIn = false;
    errorDescription?: string;
    signInForm = new FormGroup({
        email: new FormControl('', {
            validators: [Validators.required, Validators.email],
            updateOn: 'submit',
        }),
        password: new FormControl('', {
            validators: [Validators.required, Validators.minLength(6)],
            updateOn: 'submit',
        }),
        authCode: new FormControl('', {
            validators: [Validators.required],
            updateOn: 'submit',
        }),
    });

    ngOnInit(): void {
        if (this.sessionService.isLoggedIn) {
            this.navigationService.navigateToDefaultSignedInRoute();
        }
    }

    @HostListener('window:storage', ['$event'])
    onStorageChanged(event: StorageEvent) {
        if (event.key === 'user' && !event.oldValue && event.newValue) {
            this.navigationService.navigateToStoredLocation();
        }
    }

    login(event: Event, username: string, password: string, authCode: string) {
        if (!this.signInForm.valid) {
            return;
        }

        this.errorDescription = undefined;
        this.loggingIn = true;
        event.preventDefault();

        this.sessionService.signIn(username, password, authCode).subscribe({
            error: err => {
                console.error('login error:', err);
                this.errorDescription = err instanceof HttpErrorResponse ? 'Log in incorrect' : 'Undefined error';
                this.loggingIn = false;
            },
        });
    }
}
