import { Injectable } from '@angular/core';
import { ApiService } from 'app/services/api/api.service';

@Injectable({
    providedIn: 'root',
})
export class RecommendationApiService {
    constructor(private apiService: ApiService) {}

    delete(userId: string, recommendationId: string) {
        return this.apiService.delete(`/gem/sitly-users/${userId}/recommendations/${recommendationId}`);
    }
}
