import { BaseParser } from 'app/services/api/parsers/base-parser';
import { ServerResponseData } from 'app/models/api/response';
import { ParserMap } from 'app/services/api/parsers/response-parser';
import { Message } from 'app/models/api/message';

export class MessageParser extends BaseParser {
    parse(data: ServerResponseData, _included: ServerResponseData[], _parsersMap: ParserMap) {
        const item = super.parseBase(Message, data);
        item.fill();
        return item;
    }
}
