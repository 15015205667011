import { BaseApiModel } from 'app/models/api/response';

export class LocaleTranslation {
    localeId?: number;
    content?: string;
}

export interface PaginationMeta {
    totalPages: number;
    totalCount: number;
}

export class Translation extends BaseApiModel {
    translationCode = '';
    translationCodeId = 0;
    groupId = 0;
    groupName = '';
    valueDevelopment = '';
    valueProduction?: string;
    localeId = 0;
    translations: LocaleTranslation[] = [];
}
