<div
    class="page-container"
    infiniteScroll
    [infiniteScrollDistance]="4"
    [infiniteScrollThrottle]="100"
    [immediateCheck]="true"
    [scrollWindow]="false"
    (scrolled)="onScroll()"
>
    <div class="top-bar">
        <div class="tab-container">
            <div
                class="tab-header"
                [class.selected]="selectedTab === ScreeningGeneralSubRouteType.greyList"
                [routerLink]="ScreeningGeneralSubRouteType.greyList"
            >
                Grey list <span *ngIf="greyListUsersNumber" class="counter">{{ greyListUsersNumber }}</span>
            </div>
            <div
                class="tab-header"
                [class.selected]="selectedTab === ScreeningGeneralSubRouteType.blackList"
                [routerLink]="ScreeningGeneralSubRouteType.blackList"
            >
                Black list <span *ngIf="blackListUsersNumber" class="counter">{{ blackListUsersNumber }}</span>
            </div>
            <div
                class="tab-header"
                [class.selected]="selectedTab === ScreeningGeneralSubRouteType.suspects"
                [routerLink]="ScreeningGeneralSubRouteType.suspects"
            >
                Suspects <span *ngIf="suspectedUsersNumber" class="counter">{{ suspectedUsersNumber }}</span>
            </div>
            <div
                class="tab-header"
                [class.selected]="selectedTab === ScreeningGeneralSubRouteType.quarantined"
                [routerLink]="ScreeningGeneralSubRouteType.quarantined"
            >
                Quarantined
            </div>
        </div>

        <gem-select [options]="filterOptions" [placeholder]="'Show All'" (changed)="onFilterSelected($event)"> </gem-select>
    </div>

    <div class="users" *ngIf="!isOnlyPhotosView && users.length > 0">
        <div class="selector">
            <round-checkbox #selectAll id="all" label="All" (changed)="toggleAllSelection($event)"></round-checkbox>
        </div>
        <div class="users-header">
            <div class="col-select"></div>
            <div class="col-avatar">Avatar</div>
            <div class="col-user">User</div>
            <div class="col-flag">Flag</div>
            <div class="col-content">Content</div>
            <div class="col-action"></div>
        </div>
        <div>
            <div *ngFor="let item of users" class="row-user">
                <div class="col-select">
                    <round-checkbox
                        id="item.user.id"
                        [checked]="item.selected"
                        (changed)="toggleUserSelection($event, item)"
                    ></round-checkbox>
                </div>
                <a [routerLink]="['/', storageService.countryCode, 'users', item.user.id, 'profile']" target="_blank">
                    <div class="col-group-user" [class.selected]="item.selected">
                        <div class="col-avatar">
                            <div class="photo">
                                <img avatar [user]="item.user" [size]="100" />
                                <button
                                    class="btn-remove-photo"
                                    *ngIf="item.user.links?.avatar"
                                    (click)="deleteAvatar($event, item.user.id)"
                                >
                                    <img src="assets/images/user-screening-icons/delete.svg" />
                                </button>
                            </div>
                        </div>
                        <div class="col-user">
                            <span text-autoselect>
                                {{ item.user.firstName }} {{ item.user.lastName }}
                                <div class="user-status">
                                    {{ item.user.role | titlecase }} - {{ item.user.isPremium ? 'Premium' : 'Basic' }} member
                                </div>
                            </span>
                        </div>
                        <div class="col-warning">
                            <div class="sub-row-warning" *ngFor="let warningGroup of item.warningGroups">
                                <a
                                    [routerLink]="
                                        warningGroup.type === WarningType.message
                                            ? ['/', storageService.countryCode, 'users', item.user.id, 'messages', warningGroup.receiverId]
                                            : ['/', storageService.countryCode, 'users', item.user.id]
                                    "
                                    target="_blank"
                                >
                                    <div class="col-flag">
                                        <img
                                            *ngIf="selectedTab === ScreeningGeneralSubRouteType.suspects"
                                            class="img-status"
                                            [image2x]="
                                                'user-screening-icons/' +
                                                (item.user.statusRedFlag
                                                    ? 'black-listed'
                                                    : item.user.statusYellowFlag
                                                      ? 'grey-listed'
                                                      : 'not-flagged')
                                            "
                                        />
                                        <span
                                            text-autoselect
                                            class="sub-col-flag"
                                            [autoselect-active]="warningGroup.type !== WarningType.message"
                                        >
                                            <div>{{ warningGroup.type | titlecase }}</div>
                                            <div *ngFor="let warning of warningGroup.warnings" class="warning-phrase">
                                                {{ warning.warningPhrases | camelCaseToSpaceSeparated }}
                                            </div>
                                        </span>
                                    </div>
                                    <div class="col-content">
                                        <div
                                            *ngIf="warningGroup.warningPhrases && warningGroup.warningText"
                                            text-autoselect
                                            [autoselect-active]="warningGroup.type !== WarningType.message"
                                            [innerHtml]="warningGroup.warningText | highlight: warningGroup.warningPhrases"
                                        ></div>
                                        <div *ngIf="warningGroup.type === WarningType.avatar" class="col-content-photos">
                                            <div *ngFor="let photo of item.photos" class="user-photo">
                                                <img [src]="photo.url" />
                                                <button class="btn-remove-photo" (click)="deletePhoto($event, item.user.id, photo)">
                                                    <img src="assets/images/user-screening-icons/delete.svg" />
                                                </button>
                                                <div *ngIf="photo.warning" class="label warning">
                                                    {{ photo.warning.warningPhrases | camelCaseToSpaceSeparated }}
                                                </div>
                                                <div *ngIf="!photo.warning" class="label photo-type">{{ photo.type }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-action">
                            <button class="btn-remove" (click)="ignoreUser($event, item)">
                                <img image2x="approve" />
                                Remove
                            </button>
                            <button class="btn-underage" *ngIf="!item.user.isParent" (click)="deleteUnderageUser($event, item)">
                                <img image2x="underage" />
                                Underage
                            </button>
                            <button
                                class="btn-quarantine"
                                *ngIf="this.selectedTab !== ScreeningGeneralSubRouteType.quarantined"
                                (click)="blockUser($event, item.user.id)"
                            >
                                <img image2x="quarantine" />
                                Quarantine
                            </button>
                            <button class="btn-suspect" *ngIf="showSuspectBtn" (click)="suspectUser($event, item)">
                                <img image2x="suspect" />
                                Suspect
                            </button>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>

    <div class="users-grid" *ngIf="isOnlyPhotosView">
        <div *ngFor="let photo of flaggedPhotos" class="user-item">
            <img [src]="photo.url" class="photo" />
            <div class="label warning">
                {{ photo.warning?.warningPhrases | camelCaseToSpaceSeparated }}
            </div>
            <div class="label number">
                {{ photo.label }}
            </div>
            <div class="action-overlay">
                <button class="btn-delete" (click)="deletePhoto($event, photo.userId, photo)">
                    <img src="assets/images/user-screening-icons/delete.svg" />
                    Delete
                </button>
                <button class="btn-quarantine" (click)="blockUser($event, photo.userId)">
                    <img src="assets/images/user-screening-icons/quarantine.svg" />
                    Quarantine
                </button>
                <a [routerLink]="['/', storageService.countryCode, 'users', photo.userId, 'profile']" target="_blank">
                    <button class="btn-view">
                        <img src="assets/images/user-screening-icons/view.svg" />
                        View profile
                    </button>
                </a>
                <div class="divider"></div>
                <button class="btn-clear" (click)="clearUserPhotosUntil($event, photo)">Clear until here</button>
            </div>
        </div>
    </div>

    <div class="bulk-action-container" *ngIf="hasSelectedUsers">
        <div class="action-text">
            <ng-template [ngIf]="selectedUsersNumber === 1">1 selected user:</ng-template>
            <ng-template [ngIf]="selectedUsersNumber > 1">{{ selectedUsersNumber }} selected users: </ng-template>
        </div>
        <button class="btn btn-remove" (click)="ignoreSelectedUsers()">
            <img image2x="approve" />
            Remove
        </button>
        <button *ngIf="hasSelectedSitters" class="btn btn-underage" (click)="deleteUnderageSelectedUsers()">
            <img image2x="underage" />
            Underage
        </button>
        <button
            class="btn btn-quarantine"
            *ngIf="this.selectedTab !== ScreeningGeneralSubRouteType.quarantined"
            (click)="blockSelectedUsers()"
        >
            <img image2x="quarantine" />
            Quarantine
        </button>
        <button class="btn btn-delete-photo" *ngIf="selectedUsersHaveAvatar" (click)="deleteSelectedAvatars()">
            <img src="assets/images/user-screening-icons/delete.svg" />
            Delete photo
        </button>
    </div>

    <div #snackbar class="snackbar" [class.visible]="snackBarVisible"><img image2x="approve" /> {{ snackBarMessage }}</div>
</div>
