import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { BaseUserProfileTabComponent } from 'app/components/user-profile/base-user-profile-tab.component';
import { EmailTypes } from 'app/services/api/user.api.service';

@Component({
    selector: 'emails-tab',
    templateUrl: './emails-tab.component.html',
    styleUrls: ['./emails-tab.component.less'],
})
export class EmailsTabComponent extends BaseUserProfileTabComponent {
    showEmailConfirmation = false;
    sendingEmail = false;

    private cd = inject(ChangeDetectorRef);

    sendEmail(emailType: EmailTypes, extra?: Record<string, string>) {
        this.sendingEmail = true;
        this.userApiService.sendEmail(this.user.id, { emailType, ...extra }).subscribe({
            next: _ => {
                this.sendingEmail = false;
                this.showEmailConfirmation = true;
                this.cd.markForCheck();
                setTimeout(() => {
                    this.showEmailConfirmation = false;
                    this.cd.markForCheck();
                }, 3_000);
            },
            error: _ => {
                this.sendingEmail = false;
                this.cd.markForCheck();
            },
        });
    }
}
