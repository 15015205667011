import { Component, OnInit, inject } from '@angular/core';
import { Feedback } from 'app/models/api/feedback';
import { UserRoleId } from 'app/models/api/user';
import { FeedbackApiService } from 'app/services/api/feedback.api.service';
import { BaseComponent } from 'app/components/base.component';
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { PaginationMeta } from 'app/models/api/translation';

@Component({
    selector: 'feedbacks',
    templateUrl: './feedbacks.component.html',
    styleUrls: ['./feedbacks.component.less'],
})
export class FeedbacksComponent extends BaseComponent implements OnInit {
    feedbacks: Feedback[] = [];
    meta?: PaginationMeta;
    paginationPage = 1;
    roleOptions = [
        { name: 'Parents', id: UserRoleId.parent },
        { name: 'Babysitters', id: UserRoleId.babysitter },
        { name: 'Childminders', id: UserRoleId.childminder },
    ];

    feedbackFilterForm = new FormGroup({
        selectedRoleControl: new FormControl(UserRoleId.babysitter),
    });

    get totalCount() {
        return this.meta?.totalCount ?? 0;
    }
    get selectedRole() {
        return this.feedbackFilterForm.controls.selectedRoleControl.value ?? UserRoleId.babysitter;
    }

    private feedbackService = inject(FeedbackApiService);

    ngOnInit() {
        this.search(this.selectedRole);

        this.feedbackFilterForm.controls.selectedRoleControl.valueChanges.subscribe(_ => {
            this.paginationPage = 1;
            this.search(this.selectedRole, this.paginationPage);
        });
    }

    search(webroleId: UserRoleId, pageNumber = 1) {
        this.feedbackService
            .getFeedbacks(
                {
                    webroleId,
                },
                pageNumber,
            )
            .subscribe(res => {
                this.meta = res.meta;
                this.feedbacks = res.data;
            });
    }

    loadPage(event: PageEvent) {
        this.paginationPage = event.pageIndex + 1;
        this.search(this.selectedRole, this.paginationPage);
    }
}
