import { Component, inject } from '@angular/core';
import { BaseUserProfileTabComponent } from 'app/components/user-profile/base-user-profile-tab.component';
import { RecommendationApiService } from 'app/services/api/recommendation.api.service';

@Component({
    selector: 'reviews-tab',
    templateUrl: './reviews-tab.component.html',
    styleUrls: ['./reviews-tab.component.less'],
})
export class ReviewsTabComponent extends BaseUserProfileTabComponent {
    private recommendationApiService = inject(RecommendationApiService);

    removeReview(reviewId: string) {
        this.showAlert({
            title: 'Are you sure you want to delete this review?',
            confirmButtonTitle: 'Yes, delete',
        }).componentInstance.onConfirm.subscribe(() => {
            this.recommendationApiService.delete(this.user.id, reviewId).subscribe(() => this.refreshUser());
        });
    }

    protected initHeaderItems() {
        super.initHeaderItems();
        this.headerItems.push({
            text: `Reviews: ${this.user.recommendations.length}`,
        });
        if (this.user.recommendations.length > 0) {
            const score = this.user.recommendations.reduce((prev, current) => prev + current.score, 0) / this.user.recommendations.length;
            this.headerItems.push({
                text: `Average score: ${Math.round(score * 100) / 100}`,
            });
        }
    }
}
