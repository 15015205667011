import { Injectable } from '@angular/core';
import { ApiService, ParamsMap } from 'app/services/api/api.service';
import { ResponseParser } from 'app/services/api/parsers/response-parser';
import { Feedback } from 'app/models/api/feedback';
import { map } from 'rxjs/operators';
import { UserRoleId } from 'app/models/api/user';
import { PaginationMeta } from 'app/models/api/translation';

@Injectable({
    providedIn: 'root',
})
export class FeedbackApiService {
    constructor(
        private apiService: ApiService,
        private responseParser: ResponseParser,
    ) {}

    getFeedbacks(filter: { webroleId?: UserRoleId }, pageNumber = 1) {
        const params: ParamsMap = {
            page: {
                size: 100,
                number: pageNumber,
            },
            filter,
        };

        return this.apiService
            .get('/gem/feedbacks', { params })
            .pipe(map(response => this.responseParser.parseObject<Feedback[], PaginationMeta>(response)));
    }
}
