import { User } from 'app/models/api/user';
import { BaseApiModel } from 'app/models/api/response';
import { Message } from 'app/models/api/message';

export interface AutoRejectableUser {
    userId: string;
    firstName: string;
}

export class Conversation extends BaseApiModel {
    chatPartner?: User;
    unreadMessagesCount?: number;
    totalMessagesCount?: number;
    unanswered?: number;
    lastMessage?: Message;
}
