import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(value?: string, args?: string | string[]): unknown {
        if (!value || !args) {
            return value;
        }

        const argsArray = typeof args === 'string' ? [args] : args;
        const replacedValue = argsArray
            .flatMap(item => item.split(','))
            .filter(item => item.length > 0)
            .reduce((acc, current) => acc.replaceAll(current, `<span class="highlight">${current}</span>`), value);
        return this.sanitizer.bypassSecurityTrustHtml(replacedValue);
    }
}
