import { GemUser } from 'app/models/api/gem-user';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthToken } from 'app/models/auth-token';
import { ApiService } from 'app/services/api/api.service';
import { ResponseParser } from 'app/services/api/parsers/response-parser';
import { ParsedResponse } from 'app/models/api/response';

@Injectable({
    providedIn: 'root',
})
export class AuthApiService {
    constructor(
        private apiService: ApiService,
        private parser: ResponseParser,
    ) {}

    signIn(email: string, password: string, otp: string): Observable<ParsedResponse<AuthToken>> {
        return this.apiService.post('/gem/tokens', { body: { email, password, otp } }).pipe(
            map(response => {
                const parsedResponse = {
                    data: new AuthToken(
                        (response.meta as { accessToken: string })?.accessToken,
                        this.parser.parseObject<GemUser>(response).data,
                    ),
                };
                if (parsedResponse.data.user.countries.length === 0) {
                    throw new Error('user should have at least one country assigned');
                }
                return parsedResponse;
            }),
        );
    }
}
