import { Component, OnInit } from '@angular/core';
import { Environment } from 'app/services/api/translation.api.service';
import { BaseTranslationComponent } from 'app/components/translations/base-translation.component';
import { Translation } from 'app/models/api/translation';
import { finalize } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { LocaleService } from 'app/services/locale.service';

export class TranslationItem {
    selected = false;

    constructor(public translation: Translation) {}
}

@Component({
    selector: 'publish',
    templateUrl: './publish.component.html',
    styleUrls: ['./publish.component.less'],
})
export class PublishComponent extends BaseTranslationComponent implements OnInit {
    localeId = LocaleService.DEFAULT_LOCALE;
    environmentOptions = [Environment.development, Environment.production];
    sourceEnvironment = Environment.development;
    targetEnvironment = Environment.production;
    translations: TranslationItem[] = [];
    loading = false;
    pageNumber = 1;
    pageSize = this.storageService.paginatorPageSize ?? 20;
    totalPages = 0;
    totalCount = 0;

    get allSelected() {
        return !this.translations.some(item => !item.selected);
    }

    ngOnInit() {
        super.ngOnInit();
        this.refresh();
    }

    refresh() {
        if (this.sourceEnvironment === this.targetEnvironment) {
            this.translations = [];
            return;
        }

        const pageParams = { number: this.pageNumber, size: this.pageSize };
        this.translationService
            .getTranslationDifference(this.sourceEnvironment, this.targetEnvironment, pageParams, [this.localeId])
            .subscribe(response => {
                this.totalCount = response.meta?.totalCount ?? 0;
                this.totalPages = response.meta?.totalPages ?? 0;
                this.translations = response.data.map(item => new TranslationItem(item));
                this.cd.markForCheck();
            });
    }

    onPageChanged(event: PageEvent) {
        this.storageService.paginatorPageSize = event.pageSize;
        this.pageSize = event.pageSize;
        this.pageNumber = event.pageIndex + 1;
        this.refresh();
    }

    publish() {
        this.loading = true;

        this.translationService
            .publishTranslations(
                this.sourceEnvironment,
                this.targetEnvironment,
                this.translations.filter(item => item.selected).map(item => parseInt(item.translation.id)),
            )
            .pipe(
                finalize(() => {
                    this.loading = false;
                    this.cd.markForCheck();
                }),
            )
            .subscribe(() => {
                this.refresh();
            });
    }

    toggleAllSelectionState() {
        const selected = !this.allSelected;
        this.translations.forEach(item => (item.selected = selected));
    }
}
