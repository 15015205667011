<div class="page-container">
    <div class="top-bar">
        <div class="search-form">
            <input class="search-input" type="text" placeholder="Quick search" (change)="onSearchInputChange($event)" />
            <button class="btn-search">
                <img image2x="search-white" />
            </button>
        </div>

        <div class="action-container">
            <button class="btn-solid-bright-blue btn-add-phrase" (click)="toggleAddPhraseForm()">
                <img image2x="plus-white" /> Add an exclusion
            </button>
        </div>
    </div>

    <div>
        <table class="gem-table">
            <thead>
                <tr class="gem-table-header">
                    <th class="col col-select">&nbsp;</th>
                    <th class="col col-phrase">Excluded phrase</th>
                    <th class="col col-action">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr class="row" *ngIf="model.totalCount > 0">
                    <td class="col col-select">
                        <round-checkbox #selectAllInput [checked]="model.isAllSelected" (changed)="toggleSelectAll(selectAllInput.checked)">
                        </round-checkbox>
                    </td>
                    <td class="col col-phrase">All</td>
                </tr>
                <tr *ngIf="model.newItem" class="row row-new-phrase">
                    <td class="col col-select"></td>
                    <td class="col col-phrase">
                        <input #newPhraseTextInput type="text" placeholder="Type phrase to exclude" />
                    </td>
                    <td class="col col-action">
                        <button class="btn-cancel" (click)="model.newItem = undefined">Cancel</button>
                        <button class="btn-solid-green btn-add-phrase" (click)="addNewPhrase(newPhraseTextInput.value)">
                            Add exclusion
                        </button>
                    </td>
                </tr>
                <tr *ngFor="let item of model.items" class="row">
                    <td class="col col-select">
                        <round-checkbox *ngIf="!item.editMode" [checked]="item.selected" (changed)="togglePhraseSelection($event, item)">
                        </round-checkbox>
                    </td>

                    <td class="col col-phrase">
                        <span *ngIf="!item.editMode">{{ item.item.phrase }}</span>
                        <input #editPhraseTextInput [class.invisible]="!item.editMode" type="text" [value]="item.item.phrase" />
                    </td>

                    <td class="col col-action">
                        <ng-template [ngIf]="!item.editMode">
                            <button class="btn-phrase btn-edit" (click)="item.editMode = true">
                                <img image2x="edit" />
                            </button>
                            <button class="btn-phrase btn-delete" (click)="deletePhrase(item)">
                                <img image2x="bin" />
                            </button>
                        </ng-template>

                        <ng-template [ngIf]="item.editMode">
                            <button class="btn-cancel" (click)="item.editMode = false">Cancel</button>
                            <button class="btn-solid-green btn-add-phrase" (click)="updatePhrase(item, editPhraseTextInput.value)">
                                Save changes
                            </button>
                        </ng-template>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="pagination" *ngIf="model.totalCount > 0">
            <mat-paginator
                [length]="model.totalCount"
                [pageSize]="pageSize"
                [pageIndex]="model.page"
                [showFirstLastButtons]="true"
                (page)="loadPage($event)"
                aria-label="Select page"
            >
            </mat-paginator>
        </div>
    </div>

    <div class="bulk-action-container" *ngIf="hasSelectedPhrases">
        <div class="action-text">
            <ng-template [ngIf]="selectedPhrasesNumber === 1">1 selected phrase:</ng-template>
            <ng-template [ngIf]="selectedPhrasesNumber > 1">{{ selectedPhrasesNumber }} selected phrases: </ng-template>
        </div>
        <button class="btn btn-delete" (click)="deleteSelectedPhrases()">
            <img image2x="bin" />
            Delete
        </button>
    </div>
</div>
