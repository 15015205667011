<ng-template let-subscription="subscription" let-isTestVariant="isTestVariant" #subscriptionTemplate>
    <div
        class="row"
        [class.hide-border]="subscription.testVariant"
        [class.test-variant]="isTestVariant"
        (click)="editSubscription(subscription)"
    >
        <div class="col col-subscription">
            {{ subscription.duration }} {{ subscription.durationUnit }},
            {{ countrySettings?.moneyFormat?.replace('[amount]', subscription.pricePerUnit.toString()) ?? '-' }}
        </div>
        <div class="col col-role">
            {{ UserRoleId[subscription.webroleId ?? 0] }}
        </div>
        <div class="col col-age">
            {{ subscription.maxAge }}
        </div>
        <div class="col col-show-in-overview">
            {{ subscription.showInOverview ? 'yes' : 'no' }}
        </div>
        <div class="col col-actions">
            <button
                *ngIf="!subscription.testVariant"
                class="btn-solid-blue btn-delete"
                (click)="showDeleteDialog(subscription.id); $event.stopImmediatePropagation()"
            >
                <img src="assets/images/delete-white.svg" />
            </button>
            <button
                *ngIf="!isTestVariant && !subscription.testVariant"
                class="btn-link"
                (click)="createTestVariant(subscription); $event.stopImmediatePropagation()"
            >
                Create test variant
            </button>
        </div>
    </div>
</ng-template>

<div class="subscriptions-container">
    <div class="top-bar">
        <div class="select-container">
            <div class="label">Role:</div>
            <select name="role" id="role" [(ngModel)]="roleId" (change)="search()">
                <option *ngFor="let option of rolesOptions" id="{{ option }}" value="{{ option }}">
                    {{ option === '' ? 'All' : UserRoleId[option] }}
                </option>
            </select>
        </div>
        <div class="select-container">
            <div class="label">Show in overview:</div>
            <select name="showInOverview" id="showInOverview" [(ngModel)]="showInOverview" (change)="search()">
                <option *ngFor="let option of showInOverviewOptions" id="{{ option }}" value="{{ option }}">
                    {{ option === '' ? 'All' : option ? 'yes' : 'no' }}
                </option>
            </select>
        </div>
        <button class="btn-solid-bright-blue add-subscription" (click)="addSubscription()">+ Add new subscription</button>
    </div>

    <div class="gem-table">
        <div class="gem-table-header">
            <div class="col col-subscription">Subscription</div>
            <div class="col col-role">Role</div>
            <div class="col col-age">Max age</div>
            <div class="col col-show-in-overview">Show in overview</div>
            <div class="col col-actions">Actions</div>
        </div>

        <div *ngFor="let subscription of subscriptions">
            <ng-container
                [ngTemplateOutlet]="subscriptionTemplate"
                [ngTemplateOutletContext]="{ subscription: subscription, isTestVariant: false }"
            >
            </ng-container>
            <div *ngIf="subscription.testVariant" class="test-variant header">Test with id {{ subscription.testVariant.abTestId }}</div>
            <ng-container
                *ngIf="subscription.testVariant"
                [ngTemplateOutlet]="subscriptionTemplate"
                [ngTemplateOutletContext]="{ subscription: subscription.testVariant, isTestVariant: true }"
            >
            </ng-container>
        </div>
    </div>
</div>
