import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'camelCaseToSpaceSeparated',
})
export class CamelCaseToSpaceSeparatedPipe implements PipeTransform {
    transform(input?: string): string | null {
        if (!input) {
            return null;
        }

        let result = '';

        for (let i = 0; i < input.length; i++) {
            result += `${this.isUpperCase(input.charAt(i)) ? ' ' : ''}${input.charAt(i).toLowerCase()}`;
        }

        return result;
    }

    private isUpperCase(str: string) {
        return str === str.toUpperCase() && str !== str.toLowerCase();
    }
}
