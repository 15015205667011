import { Component, OnInit, inject } from '@angular/core';
import { SubscriptionApiService } from 'app/services/api/subscription.api.service';
import { BaseComponent } from 'app/components/base.component';
import { Subscription } from 'app/models/api/subscription';
import { UserRoleId, allUserRoleIds } from 'app/models/api/user';
import { switchMap } from 'rxjs/operators';
import { SubscriptionFormComponent } from 'app/components/subscriptions/subscription-form/subscription-form.component';

@Component({
    selector: 'subscriptions',
    templateUrl: './subscriptions.component.html',
    styleUrls: ['./subscriptions.component.less'],
})
export class SubscriptionsComponent extends BaseComponent implements OnInit {
    UserRoleId = UserRoleId;
    subscriptions: Subscription[] = [];
    roleId: UserRoleId | '' = '';
    rolesOptions = ['', ...allUserRoleIds] as const;
    showInOverview: '' | 0 | 1 = '';
    showInOverviewOptions = ['', 0, 1];

    private subscriptionService = inject(SubscriptionApiService);

    ngOnInit() {
        this.search();
    }

    search() {
        this.subscriptionService
            .getSubscriptions({
                ...(this.roleId !== '' ? { webroleId: this.roleId } : {}),
                ...(this.showInOverview !== '' ? { showInOverview: this.showInOverview } : {}),
            })
            .subscribe(res => {
                this.subscriptions = res.data;
            });
    }

    showDeleteDialog(subscriptionId: number) {
        this.showAlert({
            title: 'Are you sure you want to delete this subscription?',
            confirmButtonTitle: 'Yes, delete',
        })
            .componentInstance.onConfirm.pipe(switchMap(() => this.subscriptionService.deleteSubscription(subscriptionId)))
            .subscribe(() => this.search());
    }

    addSubscription() {
        this.matDialog
            .open(SubscriptionFormComponent, { data: { type: 'create' } })
            .componentInstance.onConfirm.subscribe(() => this.search());
    }

    createTestVariant(subscription: Subscription) {
        this.matDialog
            .open(SubscriptionFormComponent, { data: { type: 'create-test-variant', subscription } })
            .componentInstance.onConfirm.subscribe(() => this.search());
    }

    editSubscription(subscription: Subscription) {
        this.matDialog
            .open(SubscriptionFormComponent, { data: { type: 'edit', subscription } })
            .componentInstance.onConfirm.subscribe(() => this.search());
    }
}
