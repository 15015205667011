import { BaseApiModel } from 'app/models/api/response';
import { format } from 'date-fns';

export enum PaymentType {
    initial = 'initial',
    recurring = 'recurring',
    chargeback = 'chargeback',
    refund = 'refund',
    validation = 'validation',
}

export enum PSPType {
    apple = 'apple',
    adyen = 'adyen',
    google = 'google',
}

export enum PaymentMethodType {
    welfareVoucher = 'welfare-voucher',
    creditcard = 'creditcard',
    ideal = 'ideal',
    paypal = 'paypal',
    bcmcMobile = 'bcmc_mobile',
    applePay = 'applepay',
    googlePay = 'paywithgoogle',
    sepa = 'sepadirectdebit',
}
export class Payment extends BaseApiModel {
    amount = 0;
    created = '';
    chargebackTime = '';
    orderType = PaymentType.initial;
    status: 'UNPAID' | 'PAID' = 'UNPAID';
    psp?: PSPType;
    paymentMethod?: string;
    refunded?: number;
    pspReference?: string;

    get ddMMyyyyCreated() {
        return format(new Date(this.created), 'dd-MM-yyyy');
    }
}
