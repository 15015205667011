import { trigger, transition, style, query, animate } from '@angular/animations';

export const routesAnimation = trigger('routeAnimations', [transition('* => *', fadeIn())]);

export function fadeIn() {
    return [
        query(':enter, :leave', style({ position: 'fixed', width: '100%', height: '100%', opacity: 0 }), {
            optional: true,
        }),
        query(':enter', [animate('0.45s ease', style({ opacity: 1 }))], { optional: true }),
    ];
}
