<div class="form-container">
    <img class="logo" image2x="logo-rounded" />

    <form [formGroup]="signInForm" (submit)="login($event, email.value, password.value, authCode.value)">
        <input #email type="email" formControlName="email" placeholder="E-mail" autocomplete="username" />
        <input #password type="password" formControlName="password" placeholder="Password" autocomplete="current-password" />
        <input #authCode type="text" formControlName="authCode" placeholder="Authenticator code" autocomplete="one-time-code" />
        <button type="submit" class="btn-solid-blue btn-login" [disabled]="loggingIn">Log in</button>
    </form>

    <span *ngIf="errorDescription" class="failed">{{ errorDescription }}</span>
</div>
