<div class="page-container">
    <button class="btn-solid-bright-blue btn-add-user" (click)="navigationService.navigateTo(RouteType.addGemUser)">Add a GEM user</button>

    <div *ngIf="users.length > 0" class="gem-table users-view">
        <div class="gem-table-header">
            <div class="col col-name">Name</div>
            <div class="col col-email">Email</div>
            <div class="col col-multi-select">Countries</div>
            <div class="col col-multi-select">Locales</div>
            <div class="col col-role">Role</div>
            <div class="col col-actions">Actions</div>
        </div>

        <div *ngFor="let user of users" class="row">
            <div class="col col-name">{{ user.firstName }} {{ user.lastName }}</div>

            <div class="col col-email">
                {{ user.email }}
            </div>

            <div class="col col-multi-select">
                <button
                    *ngFor="let country of user.countries"
                    [class.removable]="user.countries.length > 1"
                    class="option-item remove-option"
                    (click)="deleteCountry(user, country.countryCode)"
                >
                    {{ country.countryCode | uppercase }}
                </button>
                <div>
                    <button
                        *ngIf="user.countries.length < allCountries.length"
                        class="option-item btn-add-option"
                        (click)="showAddCountryForUserId === user.id ? (showAddCountryForUserId = '') : (showAddCountryForUserId = user.id)"
                    >
                        Add
                    </button>
                    <div *ngIf="showAddCountryForUserId === user.id" class="add-option">
                        <button *ngFor="let option of countriesToAdd(user)" (click)="addCountry(user, option)">
                            {{ option | uppercase }}
                        </button>
                    </div>
                </div>
            </div>

            <div class="col col-multi-select">
                <button
                    *ngFor="let locale of user.locales"
                    [class.removable]="true"
                    class="option-item remove-option"
                    (click)="deleteLocale(user, locale.localeId)"
                >
                    {{ locale.name }}
                </button>
                <div>
                    <button
                        *ngIf="user.locales.length < allLocales.length"
                        class="option-item btn-add-option"
                        (click)="showAddLocaleForUserId === user.id ? (showAddLocaleForUserId = '') : (showAddLocaleForUserId = user.id)"
                    >
                        Add
                    </button>
                    <div *ngIf="showAddLocaleForUserId === user.id" class="add-option">
                        <button *ngFor="let option of localesToAdd(user)" (click)="addLocale(user, option.localeId)">
                            {{ option.name }}
                        </button>
                    </div>
                </div>
            </div>

            <div class="col col-role">
                <select name="roles" id="roles" class="role-select" [(ngModel)]="user.role" (change)="changeRole(user)">
                    <option *ngFor="let option of allGemUserRoles" [value]="option">{{ option | lowercase | titlecase }}</option>
                </select>
            </div>

            <div class="col col-actions">
                <button class="btn-reset-login" (click)="resetLogin(user.id)">Reset login</button>
                <button class="btn-remove" (click)="removeUser(user.id)">Remove</button>
            </div>
        </div>
    </div>
</div>
