import { EventEmitter, Injectable } from '@angular/core';
import {
    HttpClient,
    HttpContext,
    HttpErrorResponse,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpParameterCodec,
    HttpParams,
    HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { StorageService } from 'app/services/storage.service';
import { environment as env } from 'environments/environment';
import { ServerResponse } from 'app/models/api/response';

export type ParamsMapValue = string | number | boolean | { [key: string]: ParamsMapValue } | Array<ParamsMapValue>;
export type ParamsMap = Record<string, ParamsMapValue>;

interface RequestOptions {
    headers?:
        | HttpHeaders
        | {
              [header: string]: string | string[];
          };
    context?: HttpContext;
    observe?: 'body';
    params?:
        | HttpParams
        | {
              [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
          };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
}

export class CustomHttpParamEncoder implements HttpParameterCodec {
    encodeKey(key: string): string {
        return encodeURIComponent(key);
    }
    encodeValue(value: string): string {
        return encodeURIComponent(value);
    }
    decodeKey(key: string): string {
        return decodeURIComponent(key);
    }
    decodeValue(value: string): string {
        return decodeURIComponent(value);
    }
}

interface RequestInput {
    mainSubpath?: boolean;
    cachable?: boolean;
    body?: unknown;
    params?: ParamsMap;
    headers?: Record<string, string>;
}

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    private cache: Record<number, unknown> = {};

    constructor(
        private http: HttpClient,
        private storageService: StorageService,
    ) {}

    clearCache() {
        this.cache = {};
    }

    get<T = ServerResponse>(endpoint: string, input: RequestInput = {}): Observable<T> {
        const paramsObject = this.parsedParams(input.params);

        const appendChar = endpoint.includes('?') ? '&' : '?';
        const parsedParams = paramsObject.toString().replace(/\+/g, '%2B');
        const cacheId = this.createCacheId(`${endpoint}${appendChar}${parsedParams}`);
        if (input.cachable) {
            if (this.cache[cacheId]) {
                return new Observable(observer => {
                    observer.next(this.cache[cacheId] as T);
                    observer.complete();
                });
            }
        }

        const options: RequestOptions = {
            headers: this.getHeaders(input.headers),
            params: paramsObject,
        };

        return this.http.get<T>(this.getFullUrl(endpoint, !input.mainSubpath), options).pipe(
            tap(response => {
                if (input.cachable) {
                    this.cache[cacheId] = response;
                }
            }),
        );
    }

    put(endpoint: string, input: RequestInput) {
        return this.http.put<ServerResponse>(this.getFullUrl(endpoint, !input.mainSubpath), input.body, {
            headers: this.getHeaders(input.headers),
        });
    }

    post(endpoint: string, input: RequestInput = {}) {
        return this.http.post<ServerResponse>(this.getFullUrl(endpoint, !input.mainSubpath), input.body, {
            headers: this.getHeaders(input.headers),
        });
    }

    patch(endpoint: string, input: RequestInput) {
        const options: RequestOptions = { headers: this.getHeaders(input.headers) };
        if (input.params) {
            options.params = this.parsedParams(input.params);
        }

        return this.http.patch<ServerResponse>(this.getFullUrl(endpoint, !input.mainSubpath), input.body, options);
    }

    delete(endpoint: string, input: RequestInput = {}) {
        return this.http.delete<ServerResponse>(this.getFullUrl(endpoint, !input.mainSubpath), { headers: this.getHeaders(input.headers) });
    }

    // ----  Builders ---- //
    private getFullUrl(path: string, countrySpecific = true) {
        const subPath = (countrySpecific ? this.storageService.countryCode : null) ?? 'main';
        return `${env.apiUrl}/${subPath}${path}`;
    }

    private parsedParams(
        params?: ParamsMap,
        paramName?: string,
        result = new HttpParams({ encoder: new CustomHttpParamEncoder() }),
    ): HttpParams {
        for (const itemName in params) {
            if (itemName) {
                const value = params[itemName];
                if (value === null || value === undefined) {
                    continue;
                }

                const fullItemName = !paramName ? itemName : `${paramName}[${itemName}]`;
                if (value instanceof Array) {
                    // let arrayValue = '';
                    // for (const item of value) {
                    //     if (typeof item === 'number' || typeof item === 'string' || typeof item === 'boolean') {
                    //         arrayValue += `${arrayValue.length > 0 ? ',' : ''}${item}`;
                    //     }
                    // }
                    // result = result.append(fullItemName, arrayValue);

                    value.forEach((element, index) => {
                        if (typeof element === 'number' || typeof element === 'string' || typeof element === 'boolean') {
                            result = result.append(`${fullItemName}[${index}]`, `${element}`);
                        }
                    });
                } else if (typeof value === 'object') {
                    result = this.parsedParams(value, fullItemName, result);
                } else if (typeof value === 'number' || typeof value === 'string' || typeof value === 'boolean') {
                    result = result.append(fullItemName, `${value}`);
                }
            }
        }
        return result;
    }

    private getHeaders(suppliedHeaders: Record<string, string> = {}): HttpHeaders {
        let headers = new HttpHeaders({ 'Accept-Language': 'en-GB' });

        const apiToken = this.storageService.token;
        if (apiToken) {
            headers = headers.set('Authorization', 'Bearer ' + apiToken);
        }

        for (const key of Object.keys(suppliedHeaders)) {
            headers = headers.set(key, suppliedHeaders[key]);
        }

        return headers;
    }
    // ---- Cache ---- //
    private createCacheId(url: string) {
        let hash = 0;
        if (url.length === 0) {
            return hash;
        }

        let i: number;
        let chr: number;
        for (i = 0; i < url.length; i++) {
            chr = url.charCodeAt(i);
            hash = (hash << 5) - hash + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }
}

export class ApiInterceptor implements HttpInterceptor {
    static readonly onUnauthorized = new EventEmitter();
    static readonly onError = new EventEmitter<HttpErrorResponse>();

    intercept(request: HttpRequest<unknown>, next: HttpHandler) {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    ApiInterceptor.onUnauthorized.emit();
                } else {
                    ApiInterceptor.onError.emit(error);
                }
                return throwError(error);
            }),
        );
    }
}
