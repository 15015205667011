export enum RouteType {
    login = 'login',
    users = 'users',
    screening = 'screening',
    settings = 'settings',
    addGemUser = 'add-gem-user',
    translations = 'translations',
    subscriptions = 'subscriptions',
    feedbacks = 'feedbacks',
    notFound = '',
}
export const allRouteTypes = Object.values(RouteType);

export enum UserSubRouteType {
    profile = 'profile',
    subscription = 'subscription',
    messages = 'messages',
    chat = 'chat',
    reviews = 'reviews',
    logs = 'logs',
    matches = 'matches',
    emails = 'emails',
}
export const allUserSubRouteTypes = Object.values(UserSubRouteType);

export enum ScreeningSubRouteType {
    general = 'general',
    personalData = 'personal-data',
    sensitivePhrases = 'sensitive-phrases',
    exclusions = 'exclusions',
}

export enum ScreeningGeneralSubRouteType {
    greyList = 'greyList',
    blackList = 'blackList',
    quarantined = 'quarantined',
    suspects = 'suspects',
}

export enum TranslationsSubRouteType {
    translate = 'translate',
    add = 'add',
    publish = 'publish',
    groups = 'groups',
}
