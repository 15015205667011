import { BaseParser } from 'app/services/api/parsers/base-parser';
import { ServerResponseData } from 'app/models/api/response';
import { ParserMap } from 'app/services/api/parsers/response-parser';
import { Warning } from 'app/models/api/warning';

export class WarningParser extends BaseParser {
    parse(data: ServerResponseData, _included: ServerResponseData[], _parsersMap: ParserMap) {
        const item = super.parseBase(Warning, data);
        item.message = this.getRelationship('message', data, _included, _parsersMap);
        item.photo = this.getRelationship('photo', data, _included, _parsersMap);
        return item;
    }
}
