import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface GemSelectOption<T> {
    id?: T;
    label: string;
}

@Component({
    selector: 'gem-select',
    templateUrl: './gem-select.component.html',
    styleUrls: ['./gem-select.component.less'],
})
export class GemSelectComponent<T> {
    @Input() options: GemSelectOption<T>[] = [];
    @Input() placeholder?: string;

    @Output() changed = new EventEmitter<GemSelectOption<T>>();

    showOptions = false;
    selected?: GemSelectOption<T>;

    get value() {
        return this.selected?.id;
    }
    @Input() set value(value: T | undefined) {
        this.selected = this.options.find(item => item.id === value);
    }

    onOptionSelected(option: GemSelectOption<T>) {
        this.selected = option;
        this.showOptions = false;
        this.changed.emit(this.selected);
    }
}
