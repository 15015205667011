import { GemUser, GemUserRole } from 'app/models/api/gem-user';
import { ApiService } from 'app/services/api/api.service';
import { map } from 'rxjs/operators';
import { ResponseParser } from 'app/services/api/parsers/response-parser';
import { Injectable } from '@angular/core';
import { ServerResponseData } from 'app/models/api/response';

export interface CreateGemUserModel {
    firstName: string;
    lastName: string;
    email: string;
    role: GemUserRole;
    countries: string[];
}

@Injectable({
    providedIn: 'root',
})
export class GemUserApiService {
    constructor(
        private apiService: ApiService,
        private responseParser: ResponseParser,
    ) {}

    getMe() {
        return this.apiService
            .get('/gem/gem-users/me', { mainSubpath: true })
            .pipe(map(response => this.responseParser.parseObject<GemUser>(response)));
    }

    getUsers() {
        return this.apiService
            .get('/gem/gem-users', { mainSubpath: true })
            .pipe(map(response => this.responseParser.parseObject<GemUser[]>(response)));
    }

    updateUser(userId: string, user: Partial<GemUser | { countries: string[] } | { locales: number[] }>) {
        return this.apiService
            .patch(`/gem/gem-users/${userId}`, { body: user, mainSubpath: true })
            .pipe(map(response => this.responseParser.parseObject<GemUser>(response)));
    }

    resetLogin(userId: string) {
        return this.apiService
            .post(`/gem/gem-users/${userId}/2fa-secret`, { mainSubpath: true })
            .pipe(map(response => (response.data as ServerResponseData).attributes.secret as string));
    }

    deleteUser(userId: string) {
        return this.apiService.delete(`/gem/gem-users/${userId}`, { mainSubpath: true });
    }

    createUser(input: CreateGemUserModel) {
        return this.apiService
            .post('/gem/gem-users', { body: input, mainSubpath: true })
            .pipe(map(response => (response.data as ServerResponseData).meta as { password: string; tfaSecret: string }));
    }
}
