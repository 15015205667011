<div class="container">
    <form [formGroup]="addTranslationForm" (submit)="saveTranslation()">
        <div>
            Translation code*: <br />
            <input formControlName="translationCode" type="text" (keyup)="onInputChange()" />
        </div>

        <div>
            Translation group*: <br />
            <select formControlName="translationGroup" (change)="onInputChange()">
                <option *ngFor="let group of groups" id="{{ group.groupId }}" value="{{ group.groupId }}">
                    {{ group.name }}
                </option>
            </select>
        </div>

        <p>
            English*:
            <textarea formControlName="translationContent" (keyup)="onInputChange()"></textarea>
        </p>

        <div>
            <button class="btn-solid-bright-blue btn-save" type="submit" [disabled]="loading || addTranslationForm.invalid">Save</button>
        </div>

        <div *ngIf="errorMsg" class="error">
            <div *ngIf="errorMsg">{{ errorMsg }}</div>
        </div>
    </form>
</div>
