<div *ngIf="!showNotAllowed" class="app-content-container">
    <side-menu *ngIf="sessionService.isLoggedIn"></side-menu>

    <div class="app-container" [@routeAnimations]="prepareRoute(outlet)">
        <router-outlet #outlet="outlet"></router-outlet>
    </div>

    <div *ngIf="errorMessage" class="error-banner">
        <pre>{{ errorMessage | json }}</pre>
    </div>
</div>

<div *ngIf="showNotAllowed" class="not-allowed-country">
    <img class="logo" image2x="logo-rounded" />
    <span>You don't have access to this country.<br />Contact an admin to change this.</span>
    <button class="btn-solid-blue" (click)="closeNotAllowedOverlay()">Close</button>
</div>
