import { Pipe, PipeTransform } from '@angular/core';
import { Translation } from 'app/models/api/translation';
import { Environment } from 'app/services/api/translation.api.service';

@Pipe({
    name: 'envValue',
})
export class TranslationEnvironmentValuePipe implements PipeTransform {
    transform(value: Translation, args: Environment) {
        switch (args) {
            case Environment.development:
                return value.valueDevelopment;
            case Environment.production:
                return value.valueProduction;
        }
    }
}
