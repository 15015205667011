<div *ngIf="user" class="profile-tab">
    <user-profile-tab-header [user]="user" [listItems]="headerItems" (onUserUpdated)="onUserUpdated.emit($event)"></user-profile-tab-header>

    <div *ngIf="user.photos.length > 0 || user.links?.avatar" class="profile-section photos-container">
        <div *ngIf="user.links?.avatar && !user.links?.avatar?.includes('/photos')" class="photo">
            <div (click)="onAvatarClicked()">
                <img [src]="ImgSizeUtil.url(user.links?.avatar ?? '', 100)" />
            </div>
            <button class="btn-remove-photo" (click)="removeAvatar()">
                <img image2x="bin" />
            </button>
        </div>
        <div *ngFor="let photo of user.photos" class="photo">
            <div (click)="onPhotoClicked(photo)">
                <img [src]="photo.links?.photo" />
            </div>
            <button class="btn-remove-photo" (click)="removePhoto(photo.id)">
                <img image2x="bin" />
            </button>
        </div>
    </div>

    <div class="profile-section">
        <div class="section-row">
            <label for="firstName">Name</label>
            <div class="input-grid-container">
                <input type="text" id="firstName" name="firstName" (blur)="save($event)" [(ngModel)]="user.firstName" />
                <input type="text" id="lastName" name="lastName" (blur)="save($event)" [(ngModel)]="user.lastName" />
            </div>
        </div>

        <div class="section-row">
            <label for="email">Log in credentials</label>
            <div class="input-grid-container">
                <input type="email" id="email" name="email" autocomplete="off" (blur)="save($event)" [(ngModel)]="user.email" />
                <input type="password" id="password" name="password" placeholder="New password" autocomplete="off" (blur)="save($event)" />
            </div>
        </div>

        <div class="section-row">
            <label for="about">About</label>
            <div class="input-container">
                <textarea type="text" id="about" name="about" (blur)="save($event)" [(ngModel)]="user.about"></textarea>
            </div>
        </div>

        <button class="btn-link" (click)="sendWarningEmail()">Send a warning email</button>
        <div *ngIf="showWarningEmailConfirmation" class="warning-email-confirmation">Email sent</div>
    </div>

    <div class="profile-section">
        <div class="section-header">Profile variables</div>
        <div class="section-row">
            <div class="section-row-title">Availability</div>
            <div class="availability-container">
                <table class="availability-table" *ngIf="userAvailability">
                    <thead>
                        <th>All day</th>
                        <th>Morning</th>
                        <th>Afternoon</th>
                        <th>Evening</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let day of allWeekDays">
                            <td
                                class="day-name"
                                [class.selected]="
                                    userAvailability[day].morning && userAvailability.monday.afternoon && userAvailability[day].evening
                                "
                                (click)="toggleDayAvailability(day)"
                            >
                                {{ day | titlecase }}
                            </td>
                            <td
                                *ngFor="let dayPart of allDayParts"
                                [class.selected]="userAvailability[day][dayPart]"
                                (click)="toggleAvailability(day, dayPart)"
                            >
                                <input type="checkbox" [(ngModel)]="userAvailability[day][dayPart]" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="section-row hourly-rate" *ngIf="!user.isParent && user.fosterProperties && countrySettings">
            <label for="averageHourlyRate">Hourly rate</label>
            <select
                name="averageHourlyRate"
                id="averageHourlyRate"
                [ngModel]="user.fosterProperties.averageHourlyRate"
                (ngModelChange)="saveUserUpdate({ averageHourlyRate: $event })"
            >
                <option *ngFor="let option of countrySettings.hourlyRateOptions" [ngValue]="option.value">
                    {{ option.label }}
                </option>
            </select>
        </div>

        <div *ngIf="user.isParent && parentSearchPreferences" class="section-row looking-for">
            <div class="section-row-title">Looking for</div>
            <div class="checkbox-row">
                <input
                    type="checkbox"
                    name="lookingForBabysitters"
                    id="lookingForBabysitters"
                    [(ngModel)]="parentSearchPreferences.babysitters"
                    (change)="save($event)"
                />
                <label for="lookingForBabysitters">Babysitters</label>
            </div>
            <div class="checkbox-row">
                <input
                    type="checkbox"
                    name="lookingForChildminders"
                    id="lookingForChildminders"
                    [(ngModel)]="parentSearchPreferences.childminders"
                    (change)="save($event)"
                />
                <label for="lookingForChildminders">Childminders</label>
            </div>
        </div>

        <div *ngIf="!user.isParent && fosterChores" class="section-row chores">
            <div class="section-row-title">Additional chores</div>
            <div class="checkbox-row">
                <input
                    type="checkbox"
                    name="chores"
                    id="chores"
                    [(ngModel)]="fosterChores.chores"
                    (change)="saveUserUpdate({ fosterChores })"
                />
                <label for="chores">Light household chores</label>
            </div>
            <div class="checkbox-row">
                <input
                    type="checkbox"
                    name="driving"
                    id="driving"
                    [(ngModel)]="fosterChores.driving"
                    (change)="saveUserUpdate({ fosterChores })"
                />
                <label for="driving">Driving children</label>
            </div>
            <div class="checkbox-row">
                <input
                    type="checkbox"
                    name="shopping"
                    id="shopping"
                    [(ngModel)]="fosterChores.shopping"
                    (change)="saveUserUpdate({ fosterChores })"
                />
                <label for="shopping">Shopping</label>
            </div>
            <div class="checkbox-row">
                <input
                    type="checkbox"
                    name="cooking"
                    id="cooking"
                    [(ngModel)]="fosterChores.cooking"
                    (change)="saveUserUpdate({ fosterChores })"
                />
                <label for="cooking">Cooking</label>
            </div>
        </div>

        <div *ngIf="!user.isParent && fosterProperties" class="section-row other">
            <div class="section-row-title">Other</div>
            <div class="checkbox-row">
                <input type="checkbox" name="isSmoker" id="isSmoker" [(ngModel)]="fosterProperties.isSmoker" (change)="save($event)" />
                <label for="isSmoker">Smoker</label>
            </div>
        </div>
    </div>

    <div class="profile-section">
        <div class="section-header">Profile settings</div>
        <div class="section-row">
            <label for="receiveMatchMail">Matchmail</label>
            <select name="receiveMatchMail" id="receiveMatchMail" [(ngModel)]="user.receiveMatchMail" (change)="save($event)">
                <option value="never">Never</option>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
            </select>
        </div>

        <div class="checkbox-row">
            <input
                type="checkbox"
                name="hasPublicProfile"
                id="hasPublicProfile"
                [(ngModel)]="!user.hasPublicProfile"
                (change)="saveUserUpdate({ hasPublicProfile: !user.hasPublicProfile })"
            />
            <label for="hasPublicProfile">Don't show on public profiles</label>
        </div>
    </div>
</div>
