import { Injectable, signal } from '@angular/core';
import { CountrySettings } from 'app/models/api/country-settings-interface';

type Optional<T> = T | undefined;

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    get countrySettings() {
        return this.getObject<CountrySettings>('countrySettings');
    }
    set countrySettings(value: Optional<CountrySettings>) {
        this.setObject('countrySettings', value);
    }

    get countryCode(): Optional<string> {
        return this.getValue('countryCode');
    }
    set countryCode(value: Optional<string>) {
        this.setValue('countryCode', value);
    }

    get token(): Optional<string> {
        return this.getValue('token');
    }
    set token(value: Optional<string>) {
        this.setValue('token', value);
    }

    get user(): Optional<string> {
        return this.getValue('user');
    }
    set user(value: Optional<string>) {
        this.setValue('user', value);
    }

    readonly sideMenuCollapsed = signal(this.getBool('sideMenuCollapsed'));
    setSideMenuCollapsed(value: boolean) {
        this.setBool('sideMenuCollapsed', value);
        this.sideMenuCollapsed.set(value);
    }

    get lastUsedTranslationGroupId() {
        return this.getNumber('lastUsedTranslationGroupId');
    }
    set lastUsedTranslationGroupId(value: number | undefined) {
        this.setNumber('lastUsedTranslationGroupId', value);
    }

    get paginatorPageSize() {
        return this.getNumber('paginatorPageSize');
    }
    set paginatorPageSize(value: number | undefined) {
        this.setNumber('paginatorPageSize', value);
    }

    get storedLocation() {
        return this.getSessionStorageValue('stored-location');
    }
    set storedLocation(url: string | undefined) {
        this.setSessionStorageValue('stored-location', url);
    }

    clear() {
        ['countrySettings', 'countryCode', 'token', 'user', 'sideMenuCollapsed'].forEach(item => {
            localStorage.removeItem(item);
        });
    }

    // -- Internal -- //
    private setValue(key: string, value: Optional<string>) {
        if (value) {
            localStorage.setItem(key, value);
        } else {
            localStorage.removeItem(key);
        }
    }
    private getValue(key: string) {
        return localStorage.getItem(key) ?? undefined;
    }

    private setSessionStorageValue(key: string, value: Optional<string>) {
        if (value) {
            sessionStorage.setItem(key, value);
        } else {
            sessionStorage.removeItem(key);
        }
    }
    private getSessionStorageValue(key: string) {
        return sessionStorage.getItem(key) ?? undefined;
    }

    private setBool(key: string, value: boolean) {
        this.setValue(key, value ? 'true' : undefined);
    }
    private getBool(key: string) {
        return localStorage.getItem(key) === 'true';
    }

    private setNumber(key: string, value: number | undefined) {
        this.setValue(key, value ? `${value}` : undefined);
    }
    private getNumber(key: string) {
        const value = localStorage.getItem(key);
        return value === null ? undefined : Number(value);
    }

    private setObject(key: string, value: Optional<unknown>) {
        if (value) {
            localStorage.setItem(key, JSON.stringify(value));
        } else {
            localStorage.removeItem(key);
        }
    }
    private getObject<T>(key: string) {
        const obj = localStorage.getItem(key);
        return obj ? (JSON.parse(obj) as T) : undefined;
    }
}
