import { ServerResponseData } from 'app/models/api/response';
import { SensitivePhraseExclusion } from 'app/models/api/sensitive-phrase-exclusion';
import { BaseParser } from 'app/services/api/parsers/base-parser';
import { ParserMap } from 'app/services/api/parsers/response-parser';

export class SensitivePhraseExclusionParser extends BaseParser {
    parse(data: ServerResponseData, _included: ServerResponseData[], _parsersMap: ParserMap) {
        const item = super.parseBase(SensitivePhraseExclusion, data);
        return item;
    }
}
