import { Country } from 'app/models/api/country';
import { BaseParser } from 'app/services/api/parsers/base-parser';
import { ServerResponseData } from 'app/models/api/response';
import { ParserMap } from 'app/services/api/parsers/response-parser';

export class CountryParser extends BaseParser {
    parse(data: ServerResponseData, _included: ServerResponseData[], _parsersMap: ParserMap) {
        return super.parseBase(Country, data);
    }
}
