import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { BaseComponent } from 'app/components/base.component';
import { ParsedResponse } from 'app/models/api/response';
import { SensitivePhrase, SensitivePhraseMeta, SensitivePhraseType } from 'app/models/api/sensitive-phrase';
import { SensitivePhraseExclusion } from 'app/models/api/sensitive-phrase-exclusion';
import { Observable } from 'rxjs';

export type SensitivePhraseCommonType = SensitivePhrase | SensitivePhraseExclusion;

export class PhrasesModel<T extends SensitivePhraseCommonType> {
    items: SelectablePhraseItem<T>[] = [];
    page = 0;
    totalPages = 0;
    totalCount = 0;
    query?: string;
    filters?: string[];

    newItem?: T;

    get isAllSelected() {
        return !this.items.find(item => !item.selected);
    }
}

export class SelectablePhraseItem<T extends SensitivePhraseCommonType> {
    selected = false;
    editMode = false;

    constructor(public item: T) {}
}

@Component({
    template: '',
})
export abstract class BaseSensitivePhrasesComponent<T extends SensitivePhraseCommonType> extends BaseComponent implements OnInit {
    readonly pageSize = 40;
    model = new PhrasesModel<T>();

    get selectedPhrasesNumber() {
        return this.model.items.filter(item => item.selected).length;
    }

    get hasSelectedPhrases() {
        return this.model.items.find(item => item.selected);
    }

    get pagingBottomLineText() {
        if (this.model.items.length === 0) {
            return undefined;
        }

        const rangeStart = (this.model.page - 1) * this.pageSize + 1;
        const rangeEnd = rangeStart + this.model.items.length - 1;
        return `${rangeStart}-${rangeEnd} of ${this.model.totalCount} sensitive phrases`;
    }

    ngOnInit() {
        this.refreshTable();
    }

    togglePhraseSelection(checked: boolean, item: SelectablePhraseItem<T>) {
        item.selected = checked;
    }

    toggleAddPhraseForm() {
        if (!this.model.newItem) {
            this.model.newItem = this.createNewItem();
        } else {
            this.model.newItem = undefined;
        }
    }

    onSearchInputChange(event: Event) {
        this.model.query = (event.target as HTMLInputElement).value;
        this.refreshTable();
    }

    addNewPhrase(phrase?: string, type?: SensitivePhraseType) {
        if (phrase) {
            this.postNewItem(phrase, type).subscribe(() => {
                this.toggleAddPhraseForm();
                this.refreshTable();
            });
        }
    }

    updatePhrase(item: SelectablePhraseItem<T>, phrase?: string, type?: string) {
        this.updateItem(item.item.id, phrase, type as SensitivePhraseType).subscribe(response => {
            item.editMode = false;
            item.item = response.data;
        });
    }

    deleteSelectedPhrases() {
        this.requestToDeleteItems(this.model.items.filter(item => item.selected).map(item => item.item.id));
    }

    deletePhrase(item: SelectablePhraseItem<T>) {
        this.requestToDeleteItems([item.item.id]);
    }

    loadPage(event: PageEvent) {
        this.refreshTable(event.pageIndex);
    }

    toggleSelectAll(checked: boolean) {
        this.model.items.forEach(item => (item.selected = checked));
    }

    onFilterChanged(filters?: string[]) {
        this.model.filters = filters;
        this.refreshTable(0, this.pageSize);
    }

    abstract getItems(pageNumber: number, pageSize: number): Observable<ParsedResponse<T[], SensitivePhraseMeta>>;

    abstract createNewItem(): T;

    abstract postNewItem(phrase: string, type?: SensitivePhraseType): Observable<ParsedResponse<T>>;

    abstract updateItem(itemId: string, phrase?: string, type?: SensitivePhraseType): Observable<ParsedResponse<T>>;

    abstract deleteItems(ids: string[]): Observable<ParsedResponse<unknown>>;

    protected requestToDeleteItems(ids: string[]) {
        this.showAlert({
            title: `Are you sure you want to delete ${ids.length === 1 ? '1 phrase' : `${ids.length} phrases`}`,
            confirmButtonTitle: 'Yes, remove',
        }).componentInstance.onConfirm.subscribe(() => {
            this.deleteItems(ids).subscribe(() => this.refreshTable());
        });
    }

    private refreshTable(pageNumber = 0, pageSize = this.pageSize) {
        this.getItems(pageNumber + 1, pageSize).subscribe(response => {
            this.model.page = pageNumber;
            this.model.totalPages = response.meta?.pageCount ?? 0;
            this.model.totalCount = response.meta?.rowCount ?? 0;

            this.model.items = response.data.map(item => new SelectablePhraseItem<T>(item));
        });
    }
}
