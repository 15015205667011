export interface ApiError {
    url: string;
    error: {
        errors: {
            title: string;
        }[];
    };
}

export enum WeekDay {
    monday = 'monday',
    tuesday = 'tuesday',
    wednesday = 'wednesday',
    thursday = 'thursday',
    friday = 'friday',
    saturday = 'saturday',
    sunday = 'sunday',
}
export const allWeekDays = Object.values(WeekDay);

export enum DayPart {
    morning = 'morning',
    afternoon = 'afternoon',
    evening = 'evening',
}
export const allDayParts = Object.values(DayPart);
