import { GemUserRole } from 'app/models/api/gem-user';
import { BaseComponent } from 'app/components/base.component';
import { Component, OnDestroy, OnInit, computed, inject } from '@angular/core';
import { RouteType, ScreeningSubRouteType, TranslationsSubRouteType } from 'app/routing/route-type';
import { UserWarningApiService } from 'app/services/api/user.warning.api.service';
import { ScreeningPersonalDataApiService } from 'app/services/api/personal-data.api.service';

@Component({
    selector: 'side-menu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.less'],
})
export class SideMenuComponent extends BaseComponent implements OnInit, OnDestroy {
    GemUserRole = GemUserRole;

    readonly isScreeningRoute = computed(() => this.navigationService.routeTypeSignal() === RouteType.screening);
    readonly isUsersRoute = computed(() => this.navigationService.routeTypeSignal() === RouteType.users);
    readonly isTranslationsRoute = computed(() => this.navigationService.routeTypeSignal() === RouteType.translations);
    readonly isSubscriptionsRoute = computed(() => this.navigationService.routeTypeSignal() === RouteType.subscriptions);
    readonly isFeedbacksRoute = computed(() => this.navigationService.routeTypeSignal() === RouteType.feedbacks);
    readonly isSettingsRoute = computed(() => this.navigationService.routeTypeSignal() === RouteType.settings);

    readonly isScreeningGeneralRoute = computed(
        () => this.isScreeningRoute() && this.navigationService.allSubRoutesSignal().includes(ScreeningSubRouteType.general),
    );
    readonly isScreeningPersonalDataRoute = computed(
        () => this.isScreeningRoute() && this.navigationService.allSubRoutesSignal().includes(ScreeningSubRouteType.personalData),
    );
    readonly isScreeningSensitivePhrasesRoute = computed(
        () => this.isScreeningRoute() && this.navigationService.allSubRoutesSignal().includes(ScreeningSubRouteType.sensitivePhrases),
    );
    readonly isScreeningExclusionsRoute = computed(
        () => this.isScreeningRoute() && this.navigationService.allSubRoutesSignal().includes(ScreeningSubRouteType.exclusions),
    );

    readonly isTranslationsTranslateRoute = computed(
        () => this.isTranslationsRoute() && this.navigationService.allSubRoutesSignal().includes(TranslationsSubRouteType.translate),
    );
    readonly isTranslationsAddRoute = computed(
        () => this.isTranslationsRoute() && this.navigationService.allSubRoutesSignal().includes(TranslationsSubRouteType.add),
    );
    readonly isTranslationsPublishRoute = computed(
        () => this.isTranslationsRoute() && this.navigationService.allSubRoutesSignal().includes(TranslationsSubRouteType.publish),
    );
    readonly isTranslationsGroupsRoute = computed(
        () => this.isTranslationsRoute() && this.navigationService.allSubRoutesSignal().includes(TranslationsSubRouteType.groups),
    );

    readonly collapsed = computed(() => this.storageService.sideMenuCollapsed());

    readonly screeningGeneralRouterLink = ['/', this.storageService.countryCode, RouteType.screening, ScreeningSubRouteType.general];
    readonly screeningPersonalDataRouterLink = [
        '/',
        this.storageService.countryCode,
        RouteType.screening,
        ScreeningSubRouteType.personalData,
    ];
    readonly screeningSensitivePhrasesRouterLink = [
        '/',
        this.storageService.countryCode,
        RouteType.screening,
        ScreeningSubRouteType.sensitivePhrases,
    ];
    readonly screeningExclusionsRouterLink = ['/', this.storageService.countryCode, RouteType.screening, ScreeningSubRouteType.exclusions];

    readonly translationsTranslateRouterLink = ['/', RouteType.translations, TranslationsSubRouteType.translate];
    readonly translationsAddRouterLink = ['/', RouteType.translations, TranslationsSubRouteType.add];
    readonly translationsPublishRouterLink = ['/', RouteType.translations, TranslationsSubRouteType.publish];
    readonly translationsGroupsRouterLink = ['/', RouteType.translations, TranslationsSubRouteType.groups];
    readonly canAccessTranslations = this.gemUser.role === GemUserRole.admin || this.gemUser.role === GemUserRole.translator;
    readonly canAccessSitlyUsers =
        this.gemUser.role === GemUserRole.admin || this.gemUser.role === GemUserRole.support || this.gemUser.role === GemUserRole.tester;

    warnedUsersCount = 0;
    usersWithPersonalDataCount = 0;

    private refreshInterval?: ReturnType<typeof setInterval>;
    private userWarningApiService = inject(UserWarningApiService);
    private personalDataApiService = inject(ScreeningPersonalDataApiService);

    ngOnInit() {
        this.refreshStats();
        this.refreshInterval = setInterval(() => this.refreshStats(), 60_000);
    }

    ngOnDestroy() {
        clearInterval(this.refreshInterval);
    }

    toggleMenu() {
        this.storageService.setSideMenuCollapsed(!this.storageService.sideMenuCollapsed());
    }

    private refreshStats() {
        if (!this.canAccessSitlyUsers) {
            return;
        }

        this.userWarningApiService
            .getWarnedUsersStats()
            .subscribe(
                response =>
                    (this.warnedUsersCount =
                        (response.moderateWarningUserCount ?? 0) +
                        (response.severeWarningUserCount ?? 0) +
                        (response.suspectedUserCount ?? 0)),
            );

        this.personalDataApiService.getPersonalDataStats().subscribe(response => {
            this.usersWithPersonalDataCount = response.meta?.count ?? 0;
        });
    }
}
