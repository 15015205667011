import { BaseApiModel } from 'app/models/api/response';

export class Locale extends BaseApiModel {
    code = '';
    name = '';

    get localeId() {
        return parseInt(this.id);
    }
}
