import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'app/components/base.component';
import { allGemUserRoles, GemUserRole } from 'app/models/api/gem-user';
import { allCountries } from 'app/models/country';
import { RouteType } from 'app/routing/route-type';
import { CreateGemUserModel, GemUserApiService } from 'app/services/api/gem-user.api.service';
import { ApiError } from 'app/types';

@Component({
    selector: 'add-gem-user',
    templateUrl: './add-gem-user.component.html',
    styleUrls: ['./add-gem-user.component.less'],
})
export class AddGemUserComponent extends BaseComponent {
    allGemUserRoles = allGemUserRoles;
    allCountries = allCountries;
    selectedCountries = allCountries.reduce(
        (acc, value) => {
            acc[value] = false;
            return acc;
        },
        {} as { [key: string]: boolean },
    );
    get selectedAllCountries() {
        return !this.allCountries.some(item => !this.selectedCountries[item]);
    }
    submitting = false;
    addUserForm = new FormGroup({
        firstName: new FormControl('', {
            validators: [Validators.required],
            updateOn: 'submit',
        }),
        lastName: new FormControl('', {
            validators: [Validators.required],
            updateOn: 'submit',
        }),
        email: new FormControl('', {
            validators: [Validators.required, Validators.email],
            updateOn: 'submit',
        }),
        role: new FormControl(GemUserRole.admin, {
            validators: [Validators.required],
            updateOn: 'submit',
        }),
    });
    errorDescription?: string;

    private gemUserApiService = inject(GemUserApiService);

    selectAllCountries() {
        const selectedAllCountries = this.selectedAllCountries;
        allCountries.forEach(item => (this.selectedCountries[item] = !selectedAllCountries));
    }

    add(event: Event) {
        const countries = Object.keys(this.selectedCountries).reduce((acc, value) => {
            if (this.selectedCountries[value]) {
                acc.push(value);
            }
            return acc;
        }, [] as string[]);
        if (countries.length === 0) {
            this.errorDescription = 'At least one country should be selected';
            return;
        }

        if (!this.addUserForm.valid) {
            this.errorDescription = 'All fields are required';
            return;
        }

        this.errorDescription = undefined;
        this.submitting = true;
        event.preventDefault();

        this.gemUserApiService.createUser({ ...(this.addUserForm.value as CreateGemUserModel), countries }).subscribe(
            res => {
                this.submitting = false;
                this.showAlert({
                    title: 'New user created',
                    description: `Secret: ${res.tfaSecret}\nPassword: ${res.password}`,
                    dismissButtonTitle: 'Close',
                })
                    .afterClosed()
                    .subscribe(() => {
                        this.navigationService.navigateTo(RouteType.settings);
                    });
            },
            (err: ApiError) => {
                console.error('creation error:', err);
                this.errorDescription = err.error?.errors?.[0]?.title;
                this.submitting = false;
            },
        );
    }
}
